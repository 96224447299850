<template>
    <div class="app flex-row align-items-center multiselect-dropdown">
        <b-container>
            <b-row class="justify-content-center">
                <b-col cols="6">
                    <b-card header-tag="header">
                        <template #header>
                            <h2 class="">Edit Resource</h2>
                        </template>
                        <b-card-body>
                            <b-form v-on:submit.prevent="UpdateResource">
                                <b-form-group
                                label="Resource Name"
                                label-for="resource_name"
                                >
                                    <b-form-input
                                    type="text"
                                    name="resource_name"
                                    v-model="resource.name"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('resource_name')"
                                    placeholder="Enter resource name">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('resource_name')">
                                    {{errors.first('resource_name')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                    label="Resource Type"
                                    label-for="resource_type"
                                    >
                                    <b-form-select
                                        name="resource_type"
                                        class="cursor-pointer capitalize"
                                        v-model="resource.type"
                                        v-validate="{ required: true, max:60 }"
                                        :state="validateState('resource_type')"
                                        :options="resourceTypeOptions"
                                        placeholder="Select resource type"
                                    >
                                    <option disabled value="">Select resource type</option>
                                    </b-form-select>
                                    <b-form-invalid-feedback v-if="errors.has('resource_type')">
                                        {{ errors.first('resource_type') }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="Resource Access"
                                label-for="resource_roles"
                                >
                                <b-form-select
                                    name="resource_roles"
                                    class="cursor-pointer capitalize"
                                    v-model="resource.roles"
                                    v-validate="{ required: true, max:60 }"
                                    :state="validateState('resource_roles')"
                                    :options="resourceRoleOptions"
                                    placeholder="Select resource roles"
                                >
                                <option disabled value="">Select resource roles</option>
                                </b-form-select>
                                <b-form-invalid-feedback v-if="errors.has('resource_roles')">
                                    {{ errors.first('resource_roles') }}
                                </b-form-invalid-feedback>
                                </b-form-group>  
                                <b-form-group
                                label="Description"
                                label-for="description"
                                >
                                    <b-form-textarea
                                    name="description"
                                    v-model="resource.description"
                                    rows="3"
                                    max-rows="6"
                                    v-validate="{ required: true, max:2000 }"
                                    :state="validateState('description')"
                                    placeholder="Enter resource description">
                                    </b-form-textarea>
                                    <b-form-invalid-feedback v-if="errors.has('description')">
                                    {{errors.first('description')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group
                                label="URL"
                                label-for="email"
                                >
                                    <b-form-input
                                    type="text"
                                    name="url"
                                    v-model="resource.url"
                                    v-validate="{ required: true, regex: /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$/ }"
                                    :state="validateState('url')"
                                    placeholder="Enter URL of resource"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors.has('url')">
                                    {{errors.first('url')}}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                                <b-form-group label="Select Community:">
                                    <multiselect 
                                        v-model="communityValues" 
                                        name="community" 
                                        v-validate="'required'" 
                                        :options="communityOptions" 
                                        :multiple="false" 
                                        label="name" 
                                        track-by="name" 
                                        placeholder="Assign a community" 
                                        @select="updateCircleSelect" 
                                        @remove="removeCircles">
                                    </multiselect>
                                    <div class="selection-required" v-if="errors.has('community')">
                                        {{errors.first('community')}}
                                    </div>
                                </b-form-group>
                                <b-form-group label="Select Circle:">
                                    <multiselect 
                                        v-model="circleValues" 
                                        name="circle" 
                                        :options="circleOptions" 
                                        :multiple="false" 
                                        label="name" 
                                        track-by="name" 
                                        placeholder="Assign a circle" 
                                        :allow-empty="true">
                                    </multiselect>
                                </b-form-group>
                                <b-button type="submit" variant="primary tab-focus">Update</b-button>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
export default {
    name: 'AddResource',
    components: { Multiselect },
    data() {
        return {
            token: localStorage.getItem('token'),
            resourceId: '',
            resource: {
                name: '',
                type: '',
                roles: '',
                description: '',
                url: '',
                communityValues: [],
                communities: [],
                circleValues: [],
                communityId: '',
                circles: '',
                circleId: ''
            },
            communities: [],
            communityOptions: [],
            communityValues: [],
            circleOptions: [],
            circleValues: [],
            resourceTypeOptions: ['job', 'staff', 'event', 'document', 'course', 'website'],
            resourceRoleOptions: ['student', 'alum', 'faculty', 'all_members'],
            checkCircleOnce: true
        }
    },
    created() {
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        // if (authUser && authUser.role === 'Admin') {
        //     this.$router.push('/');
        // }
        if(!this.$route.params.resource_id) {
            this.$router.push('/');
        }
        this.resourceId = this.$route.params.resource_id;
        this.loadData();
        const dict = {
            custom: {
                resource_name: {
                    required: "Please enter resource name.",
                    max: "resource name cannot be more than 60 characters.",
                },
                description: {
                    required: "Please enter resource description.",
                    max: "resource description cannot be more than 2000 characters.",
                },
                url: {
                    required: "Please enter URL.",
                    regex: "Please enter valid URL"
                }
            }
        };
        this.$validator.localize('en', dict);
    },
    methods: {
        async loadData() {
            await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/filter-community`, {headers: {'x-access-token': this.token}})
                    .then(response => {
                        if(response.status === 200) {
                            if(Array.isArray(response.data)) {
                                this.communities = response.data;
                            }
                            if(this.communities.length) {
                                this.communityOptions = this.communities.map(community => ({
                                    name: community.name,
                                    id: community._id,
                                    additionalParams: community.additionalParams
                                }));
                            }
                        }
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/resource/${this.resourceId}`, {headers: {'x-access-token': this.token}}).then(response => {
                    if(response.data.resource) {
                        this.resource = response.data.resource;
                        const userComm = this.resource.community;
                        const community = this.communities.find(community => community._id === userComm);

                        if (community) {
                            this.communityValues = {
                                name: community.name,
                                id: community._id,
                                additionalParams: community.additionalParams
                            };
                        }
                        this.updateCircleSelect();
                }
                    }).catch(err => {
                console.log(err);
                if (err.response.status === 401) {
                    localStorage.removeItem('authUser');
                    this.$router.push('/login');
                }
            });
        },

        UpdateResource() {
            this.$validator.validateAll().then(result => {
                this.prepareResourceData();
                if(result) {
                    let payload = {
                        name: this.resource.name,
                        type: this.resource.type,
                        roles: this.resource.roles,
                        url: this.resource.url,
                        description: this.resource.description,
                        community: this.resource.communityId,
                        communities: this.resource.communities,
                        circle: (this.resource.circles) ? this.resource.circles : '',
                        circleId: (this.resource.circleId) ? this.resource.circleId : ''
                    }
                    
                    this.axios.put(`${process.env.VUE_APP_API_BASE_URL}/dashboard/resource/${this.resourceId}/update`, payload, {headers: {'x-access-token': this.token}}).then(async response => {
                        this.$router.push('/dashboard/resources');
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            });
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        prepareResourceData() {
            if (this.communityValues) {
                this.resource.communityId = this.communityValues.id;
            }
            if (this.circleValues) {
                console.log('this.circleValues :',this.circleValues );
                this.resource.circles = this.circleValues.short;
                this.resource.circleId = this.circleValues.id;
            }
        },
        updateCircleSelect(community) {
                this.circleValues = '';
                const selectedCommunityId = this.communityValues.id;
                const circles = [];
                if (selectedCommunityId) {
                    const community = this.communities.find(c => c._id === selectedCommunityId);
                    if (community) {
                        circles.push(...(community.additionalParams || []));
                    }
                }
                this.circleOptions = circles
                    .filter(circle => circle != null && circle.name != undefined && circle.name != null && circle.name != '')
                    .map(circle => ({
                        name: circle.name,
                        id: circle._id,
                        short: circle.short,
                }));
                    if(this.checkCircleOnce){
                        this.circleValues = circles
                        .filter(circle => circle !== null && this.resource.circleId.includes(circle._id))
                        .map(circle => ({
                            name: circle.name,
                            short: circle.short,
                            id: circle._id
                        }))[0] || {};
                    this.checkCircleOnce = false;
                }
        },
        removeCircles(removedCommunity) {
            this.updateCircleSelect();
        },
        capitalizedOptions(options) {
            let capitalizedOptions = [];
            capitalizedOptions = options.map(option => {
                return option.charAt(0).toUpperCase() + option.slice(1);
            });
            return capitalizedOptions;
        }
    }
}
</script>
