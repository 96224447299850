<template>
    <div class="mb-50">
        <div class="request-status-block">
            <vue-html2pdf
                :show-layout="false"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1100"
                :filename="member.displayName"
                :pdf-quality="2"
                :manual-pagination="true"
                pdf-format="a4"
                pdf-content-width="1105px"
                pdf-orientation="landscape"
                :html-to-pdf-options=htmlToPdfOptions
                ref="html2Pdf"
                >
                <section slot="pdf-content">
                    <b-row>
                        <b-col md="12">
                            <b-card>
                                <b-row class="ml-38">
                                    <b-col md="12">
                                        <template v-if="member.isDeleted && member.isDeleted.status === true">
                                            <div class="unsubscribe font-18">Deleted: {{member.isDeleted.when | momentDate}}</div>
                                        </template>
                                        <template v-else-if="renderUnsubscribe">
                                            <div class="unsubscribe font-18">Unsubscribed: {{member.unsubscribe.when | momentDate}}</div>
                                        </template>
                                        <template>
                                            <h4 class="md-title mt-2 member-name">
                                                {{ member.first }} {{ member.last }}
                                                <!-- <span v-if="commentCount">{{commentCount}}</span> -->
                                            </h4>
                                            <div class="md-body-1 capitalize member-title mb-22" v-if="member.roles && member.roles.length && member.organization">{{ member.roles.toString() }}, {{ member.organization }}</div>
                                            <div class="md-body-1 capitalize member-title mb-22" v-else-if="member.roles && member.roles.length && !member.organization">{{ member.roles.toString() }}</div>
                                            <div class="md-body-1 capitalize member-title mb-22" v-else-if="!member.roles && member.organization">{{ member.organization }}</div>
                                        </template>
                                    </b-col>
                                    <b-col md="12">
                                        <b-row class="h-100">
                                            <b-col md="3">
                                                <ul class="card-killsblock">
                                                    <li>Member Type:</li>
                                                    <li>
                                                        <span>{{member.memberType }}</span>
                                                    </li>
                                                    <li>Status:</li>
                                                    <li>
                                                        <span v-if="member.member_status === true">
                                                            <span >Active</span>
                                                        </span>
                                                        <span v-else>
                                                            <span>Inactive</span>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </b-col>
                                            <b-col md="3">
                                                <ul class="card-killsblock">
                                                    <li>Requests:</li>
                                                    <li>
                                                        <span v-if="$lodash.has(member.request, 'totalRequest')">{{member.request.totalRequest}}</span>
                                                        <span v-else>N/A</span>
                                                    </li>
                                                    <li>Last Request:</li>
                                                    <li>
                                                        <span v-if="$lodash.has(member.request, 'lastRequest')">{{member.request.lastRequest | momentDate}}</span>
                                                        <span v-else>N/A</span>
                                                    </li>
                                                </ul>
                                            </b-col>
                                            <b-col md="3">
                                                <ul class="card-killsblock">
                                                    <li>Responses:</li>
                                                    <li>
                                                        <span v-if="$lodash.has(member.response, 'totalResponse')">{{member.response.totalResponse}}</span>
                                                        <span v-else>N/A</span>
                                                    </li>
                                                    <li>Last Response:</li>
                                                    <li>
                                                        <span v-if="$lodash.has(member.response, 'lastResponse')">{{member.response.lastResponse | momentDate}}</span>
                                                        <span v-else>N/A</span>
                                                    </li>
                                                </ul>
                                            </b-col>
                                            <b-col md="3" class="pr-0">
                                                <ul class="card-killsblock">
                                                    <li>Feedback Given:</li>
                                                    <li>
                                                        <span v-if="member.feedback_given > 0">{{member.feedback_given}}</span><span v-else>&nbsp;N/A</span>
                                                    </li>
                                                    <li>Feedback Received:</li>
                                                    <li>
                                                        <span v-if="member.feedback_received > 0">&nbsp;{{member.feedback_received}}</span><span v-else>&nbsp;N/A</span>
                                                    </li>
                                                </ul>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <b-card>
                                <b-row class="ml-38">
                                    <b-col md="12">
                                        <div class="member-detail-flex px-0" v-if="group">
                                            <div class="card-item md-subheading mt-30">Groups</div>
                                            <div>{{group}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.memberType">
                                            <div class="card-item md-subheading mt-30">Member Type</div>
                                            <div>{{member.memberType}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.member_role">
                                            <div class="card-item md-subheading mt-30">Member Role</div>
                                            <div>{{member.member_role}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.circles && member.circles.length">
                                            <div class="card-item md-subheading mt-30">Circle</div>
                                            <div>{{member.circles.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.member_rank && member.member_rank.length">
                                            <div class="card-item md-subheading mt-30">Member Rank</div>
                                            <div>{{member.member_rank.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.advice_area && member.advice_area.length">
                                            <div class="card-item md-subheading mt-30">Advice Area</div>
                                            <div>{{member.advice_area.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.affinity && member.affinity.length">
                                            <div class="card-item md-subheading mt-30">Affinity</div>
                                            <div>{{member.affinity.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.propertyId">
                                            <div class="card-item md-subheading mt-30">Property ID</div>
                                            <div>{{member.propertyId}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.studentId">
                                            <div class="card-item md-subheading mt-30">Student ID</div>
                                            <div>{{member.studentId}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.first">
                                            <div class="card-item md-subheading mt-30">First Name</div>
                                            <div>{{member.first}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.last">
                                            <div class="card-item md-subheading mt-30">Last Name</div>
                                            <div>{{member.last}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.emails && member.emails.length">
                                            <div class="card-item md-subheading mt-30">Email Address</div>
                                            <div>{{member.emails[0]}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.email_address2">
                                            <div class="card-item md-subheading mt-30">Email Address 2</div>
                                            <div>{{member.email_address2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.email_address3">
                                            <div class="card-item md-subheading mt-30">Email Address 3</div>
                                            <div>{{member.email_address3}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.phoneNumbers && member.phoneNumbers.length">
                                            <div class="card-item md-subheading mt-30">Phone</div>
                                            <div>{{member.phoneNumbers[0]}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.roles && member.roles.length">
                                            <div class="card-item md-subheading mt-30">Role</div>
                                            <div>{{member.roles[0]}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.roleDescription">
                                            <div class="card-item md-subheading mt-30">Role Description</div>
                                            <div>{{member.roleDescription}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.organization">
                                            <div class="card-item md-subheading mt-30">Organization</div>
                                            <div>{{member.organization}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.organizationDescription">
                                            <div class="card-item md-subheading mt-30">Organization Description</div>
                                            <div>{{member.organizationDescription}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.city">
                                            <div class="card-item md-subheading mt-30">City</div>
                                            <div>{{member.city}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.state">
                                            <div class="card-item md-subheading mt-30">State</div>
                                            <div>{{member.state}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.postal_code">
                                            <div class="card-item md-subheading mt-30">Postal Code</div>
                                            <div>{{member.postal_code}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.country">
                                            <div class="card-item md-subheading mt-30">Country</div>
                                            <div>{{member.country}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.citizenship1">
                                            <div class="card-item md-subheading mt-30">Citizenship 1</div>
                                            <div>{{member.citizenship1}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.citizenship2">
                                            <div class="card-item md-subheading mt-30">Citizenship 2</div>
                                            <div>{{member.citizenship2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.portalURL">
                                            <div class="card-item md-subheading mt-30">Portal URL</div>
                                            <div>{{member.portalURL}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.industry && member.industry.length">
                                            <div class="card-item md-subheading mt-30">Industry</div>
                                            <div>{{member.industry.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.bio">
                                            <div class="card-item md-subheading mt-30">Bio</div>
                                            <div>{{member.bio}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.bioKeywords && member.bioKeywords.length">
                                            <div class="card-item md-subheading mt-30">Bio Keyword</div>
                                            <div>{{member.bioKeywords.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.linkedIn">
                                            <div class="card-item md-subheading mt-30">LinkedIn URL</div>
                                            <div>{{member.linkedIn}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.skills && member.skills.length">
                                            <div class="card-item md-subheading mt-30">Skills & Expertise</div>
                                            <div>{{member.skills.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.interests && member.interests.length">
                                            <div class="card-item md-subheading mt-30">Interests</div>
                                            <div>{{member.interests.join(', ')}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.marketingOptIn">
                                            <div class="card-item md-subheading mt-30">Marketing Opt-In?</div>
                                            <div>{{member.marketingOptIn}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.race">
                                            <div class="card-item md-subheading mt-30">Race</div>
                                            <div>{{member.race}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.gender">
                                            <div class="card-item md-subheading mt-30">Gender</div>
                                            <div>{{member.gender}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.age">
                                            <div class="card-item md-subheading mt-30">Age</div>
                                            <div>{{member.age}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.calendar">
                                            <div class="card-item md-subheading mt-30">Online Calendar URL</div>
                                            <div>{{member.calendar}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.twitter">
                                            <div class="card-item md-subheading mt-30">Twitter URL</div>
                                            <div>{{member.twitter}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.engagementPoints">
                                            <div class="card-item md-subheading mt-30">Engagement Points</div>
                                            <div>{{member.engagementPoints}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.requestStatus">
                                            <div class="card-item md-subheading mt-30">Approved for Requests?</div>
                                            <div class="capitalize">{{member.requestStatus}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.responseStatus">
                                            <div class="card-item md-subheading mt-30">Approved for responses?</div>
                                            <div class="capitalize">{{member.responseStatus}}</div>
                                        </div>
                                         <div class="member-detail-flex px-0" v-if="member.termsAccepted">
                                            <div class="card-item md-subheading mt-30">Terms Accepted</div>
                                            <div class="capitalize">{{member.termsAccepted | momentDate}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0">
                                            <div class="card-item md-subheading mt-30">Frequency</div>
                                            <div v-if="member.frequency">{{ toTitleCase(member.frequency) }}</div>
                                            <div v-else>Once A Week</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.capacity">
                                            <div class="card-item md-subheading mt-30">Capacity</div>
                                            <div>{{member.capacity}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.engagement">
                                            <div class="card-item md-subheading mt-30">Engagement</div>
                                            <div>{{member.engagement}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.propensity">
                                            <div class="card-item md-subheading mt-30">Propensity</div>
                                            <div>{{member.propensity}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.instagram">
                                            <div class="card-item md-subheading mt-30">Instagram URL</div>
                                            <div>{{member.instagram}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.skypeID">
                                            <div class="card-item md-subheading mt-30">Skype ID</div>
                                            <div>{{member.skypeID}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.school1">
                                            <div class="card-item md-subheading mt-30">School 1</div>
                                            <div>{{member.school1}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.department1">
                                            <div class="card-item md-subheading mt-30">Department 1</div>
                                            <div>{{member.department1}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.major1">
                                            <div class="card-item md-subheading mt-30">Major 1</div>
                                            <div>{{member.major1}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.degree1">
                                            <div class="card-item md-subheading mt-30">Degree 1</div>
                                            <div>{{member.degree1}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.year1">
                                            <div class="card-item md-subheading mt-30">Year 1</div>
                                            <div>{{member.year1}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.school2">
                                            <div class="card-item md-subheading mt-30">School 2</div>
                                            <div>{{member.school2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.department2">
                                            <div class="card-item md-subheading mt-30">Department 2</div>
                                            <div>{{member.department2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.major2">
                                            <div class="card-item md-subheading mt-30">Major 2</div>
                                            <div>{{member.major2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.degree2">
                                            <div class="card-item md-subheading mt-30">Degree 2</div>
                                            <div>{{member.degree2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.year2">
                                            <div class="card-item md-subheading mt-30">Year 2</div>
                                            <div>{{member.year2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.school3">
                                            <div class="card-item md-subheading mt-30">School 3</div>
                                            <div>{{member.school3}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.department3">
                                            <div class="card-item md-subheading mt-30">Department 3</div>
                                            <div>{{member.department3}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.major3">
                                            <div class="card-item md-subheading mt-30">Major 3</div>
                                            <div>{{member.major3}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.degree3">
                                            <div class="card-item md-subheading mt-30">Degree 3</div>
                                            <div>{{member.degree3}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.year3">
                                            <div class="card-item md-subheading mt-30">Year 3</div>
                                            <div>{{member.year3}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.school4">
                                            <div class="card-item md-subheading mt-30">School 4</div>
                                            <div>{{member.school4}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.department4">
                                            <div class="card-item md-subheading mt-30">Department 4</div>
                                            <div>{{member.department4}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.major4">
                                            <div class="card-item md-subheading mt-30">Major 4</div>
                                            <div>{{member.major4}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.degree4">
                                            <div class="card-item md-subheading mt-30">Degree 4</div>
                                            <div>{{member.degree4}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.year4">
                                            <div class="card-item md-subheading mt-30">Year 4</div>
                                            <div>{{member.year4}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.school5">
                                            <div class="card-item md-subheading mt-30">School 5</div>
                                            <div>{{member.school5}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.department5">
                                            <div class="card-item md-subheading mt-30">Department 5</div>
                                            <div>{{member.department5}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.major5">
                                            <div class="card-item md-subheading mt-30">Major 5</div>
                                            <div>{{member.major5}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.degree5">
                                            <div class="card-item md-subheading mt-30">Degree 5</div>
                                            <div>{{member.degree5}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.year5">
                                            <div class="card-item md-subheading mt-30">Year 5</div>
                                            <div>{{member.year5}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.imageURL">
                                            <div class="card-item md-subheading mt-30">Image URL</div>
                                            <div>{{member.imageURL}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.website">
                                            <div class="card-item md-subheading mt-30">Website URL</div>
                                            <div>{{member.website}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.facebook">
                                            <div class="card-item md-subheading mt-30">Facebook URL</div>
                                            <div>{{member.facebook}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.companyStage">
                                            <div class="card-item md-subheading mt-30">Company Stage</div>
                                            <div>{{member.companyStage}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.annualRevenue">
                                            <div class="card-item md-subheading mt-30">Annual Revenue</div>
                                            <div>{{member.annualRevenue}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.teamSize">
                                            <div class="card-item md-subheading mt-30">Team Size</div>
                                            <div>{{member.teamSize}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.wordPressLink">
                                            <div class="card-item md-subheading mt-30">WordPress Link</div>
                                            <div>{{member.wordPressLink}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.customer_custom1">
                                            <div class="card-item md-subheading mt-30">Custom Value 1</div>
                                            <div>{{member.customer_custom1}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.customer_custom2">
                                            <div class="card-item md-subheading mt-30">Custom Value 2</div>
                                            <div>{{member.customer_custom2}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.customer_custom3">
                                            <div class="card-item md-subheading mt-30">Custom Value 3</div>
                                            <div>{{member.customer_custom3}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.account_owner && member.account_owner.length">
                                            <div class="card-item md-subheading mt-30">Account Owner</div>
                                            <div>{{member.account_owner.join(', ')}}</div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </section>
            </vue-html2pdf>
            <div>
                <b-row>
                    <b-col md="12">
                        <b-card>
                            <b-row class="ml-38">
                                <b-col md="12">
                                    <b-dropdown class="" v-b-tooltip.hover.bottom title="Take action" variant="btn btn-dropbox btn-sm tab-focus" right style="position: absolute;right: 15px;">
                                        <template slot="button-content">
                                            <i class="icon-settings"></i>
                                        </template>
                                        <b-dropdown-item v-if="!member.unsubscribe" @click="unsubscribeMember(member._id, member.emails, member.community)" >Unsubscribe Member</b-dropdown-item>
                                        <b-dropdown-item v-if="member.unsubscribe" @click="subscribeMember(member._id, member.emails, member.community)" >Subscribe Member</b-dropdown-item>
                                        <b-dropdown-item @click="openEmailModal(member.emails, member._id, member.community)" v-if="member._id && member.community && !member.email_address3 && !member.unsubscribe">Edit Email</b-dropdown-item>
                                        <b-dropdown-item @click="generateReport()">Print Profile</b-dropdown-item>
                                        <b-dropdown-item @click="openComment()" class="member-comment-info">
                                            Add Comment
                                            <!-- <span class="count" v-if="commentCount">{{commentCount}}</span> -->
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="toggleMemberFlag()">
                                            {{member.memberFlag == 'Flagged' ? 'Unflag' : 'Flag'}}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                <!-- <div class="avatar">
                                    <img class="circle-image" v-if="member.imageURL" :src="member.imageURL" alt="member photo">
                                    <img class="circle-image" v-if="!member.imageURL" src="../../../assets/person.png" alt="member photo">
                                </div> -->
                                <template v-if="member.isDeleted && member.isDeleted.status === true">
                                    <div class="unsubscribe font-18">Deleted: {{member.isDeleted.when | momentDate}}</div>
                                </template>
                                <template v-else-if="renderUnsubscribe">
                                    <div class="unsubscribe font-18">Unsubscribed: {{member.unsubscribe.when | momentDate}}</div>
                                </template>
                                <template>
                                    <h4 class="md-title mt-2 member-name">
                                        {{ member.first }} {{ member.last }}
                                        <span v-if="commentCount">{{commentCount}}</span>
                                    </h4>
                                    <div class="md-body-1 capitalize member-title mb-22" v-if="member.roles && member.roles.length && member.organization">{{ member.roles.toString() }}, {{ member.organization }}</div>
                                    <div class="md-body-1 capitalize member-title mb-22" v-else-if="member.roles && member.roles.length && !member.organization">{{ member.roles.toString() }}</div>
                                    <div class="md-body-1 capitalize member-title mb-22" v-else-if="!member.roles && member.organization">{{ member.organization }}</div>
                                </template>
                            </b-col>
                            <b-col md="12">
                                <b-row class="h-100">
                                    <b-col md="3">
                                        <ul class="card-killsblock">
                                            <li>Member Type:</li>
                                            <li>
                                                <span>{{member.memberType }}</span>
                                            </li>
                                            <li>Status:</li>
                                            <li>
                                                <span v-if="member.member_status === true">
                                                    <span >Active</span>
                                                </span>
                                                <span v-else>
                                                    <span>Inactive</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </b-col>
                                    <b-col md="3">
                                        <ul class="card-killsblock">
                                            <li>Requests:</li>
                                            <li>
                                                <span v-if="$lodash.has(member.request, 'totalRequest')">{{member.request.totalRequest}}</span>
                                                <span v-else>N/A</span>
                                            </li>
                                            <li>Last Request:</li>
                                            <li>
                                                <span v-if="$lodash.has(member.request, 'lastRequest')">{{member.request.lastRequest | momentDate}}</span>
                                                <span v-else>N/A</span>
                                            </li>
                                        </ul>
                                    </b-col>
                                    <b-col md="3">
                                        <ul class="card-killsblock">
                                            <li>Responses:</li>
                                            <li>
                                                <span v-if="$lodash.has(member.response, 'totalResponse')">{{member.response.totalResponse}}</span>
                                                <span v-else>N/A</span>
                                            </li>
                                            <li>Last Response:</li>
                                            <li>
                                                <span v-if="$lodash.has(member.response, 'lastResponse')">{{member.response.lastResponse | momentDate}}</span>
                                                <span v-else>N/A</span>
                                            </li>
                                        </ul>
                                    </b-col>
                                    <b-col md="3" class="pr-0">
                                        <ul class="card-killsblock">
                                            <li>Feedback Given:</li>
                                            <li>
                                                <span v-if="member.feedback_given > 0">{{member.feedback_given}}</span><span v-else>&nbsp;N/A</span>
                                            </li>
                                            <li>Feedback Received:</li>
                                            <li>
                                                <span v-if="member.feedback_received > 0">&nbsp;{{member.feedback_received}}</span><span v-else>&nbsp;N/A</span>
                                            </li>
                                        </ul>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-card>
                            <c-switch color="success" title="Edit member Details" @change.native="changeMemberDetailsStatus" name="memberDetails" v-model="editMemberDetailStatus" label variant="pill" size="sm" class="float-right edit-member-details-switch"/>
                            <b-row  v-if="!editMemberDetailStatus"  class="ml-38">
                                <b-col md="12">
                                    <div class="member-detail-flex px-0" v-if="group">
                                        <div class="card-item md-subheading mt-30">Groups</div>
                                        <div>{{group}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.memberType">
                                        <div class="card-item md-subheading mt-30">Member Type</div>
                                        <div>{{member.memberType}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.member_role">
                                        <div class="card-item md-subheading mt-30">Member Role</div>
                                        <div>{{member.member_role}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.member_rank && member.member_rank.length">
                                        <div class="card-item md-subheading mt-30">Member Rank</div>
                                        <div>{{member.member_rank.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.circles && member.circles.length">
                                        <div class="card-item md-subheading mt-30">Circle</div>
                                        <div>{{member.circles.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.advice_area && member.advice_area.length">
                                        <div class="card-item md-subheading mt-30">Advice Area</div>
                                        <div>{{member.advice_area.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.affinity && member.affinity.length">
                                        <div class="card-item md-subheading mt-30">Affinity</div>
                                        <div>{{member.affinity.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.propertyId">
                                        <div class="card-item md-subheading mt-30">Property ID</div>
                                        <div>{{member.propertyId}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.studentId">
                                        <div class="card-item md-subheading mt-30">Student ID</div>
                                        <div>{{member.studentId}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.first">
                                        <div class="card-item md-subheading mt-30">First Name</div>
                                        <div>{{member.first}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.last">
                                        <div class="card-item md-subheading mt-30">Last Name</div>
                                        <div>{{member.last}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.emails && member.emails.length">
                                        <div class="card-item md-subheading mt-30">Email Address</div>
                                        <div>{{member.emails[0]}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.email_address2">
                                        <div class="card-item md-subheading mt-30">Email Address 2</div>
                                        <div>{{member.email_address2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.email_address3">
                                        <div class="card-item md-subheading mt-30">Email Address 3</div>
                                        <div>{{member.email_address3}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.phoneNumbers && member.phoneNumbers.length">
                                        <div class="card-item md-subheading mt-30">Phone</div>
                                        <div>{{member.phoneNumbers[0]}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.roles && member.roles.length">
                                        <div class="card-item md-subheading mt-30">Role</div>
                                        <div>{{member.roles[0]}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.roleDescription">
                                        <div class="card-item md-subheading mt-30">Role Description</div>
                                        <div>{{member.roleDescription}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.organization">
                                        <div class="card-item md-subheading mt-30">Organization</div>
                                        <div>{{member.organization}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.organizationDescription">
                                        <div class="card-item md-subheading mt-30">Organization Description</div>
                                        <div>{{member.organizationDescription}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.city">
                                        <div class="card-item md-subheading mt-30">City</div>
                                        <div>{{member.city}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.state">
                                        <div class="card-item md-subheading mt-30">State</div>
                                        <div>{{member.state}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.postal_code">
                                        <div class="card-item md-subheading mt-30">Postal Code</div>
                                        <div>{{member.postal_code}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.country">
                                        <div class="card-item md-subheading mt-30">Country</div>
                                        <div>{{member.country}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.citizenship1">
                                        <div class="card-item md-subheading mt-30">Citizenship 1</div>
                                        <div>{{member.citizenship1}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.citizenship2">
                                        <div class="card-item md-subheading mt-30">Citizenship 2</div>
                                        <div>{{member.citizenship2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.portalURL">
                                        <div class="card-item md-subheading mt-30">Portal URL</div>
                                        <div>{{member.portalURL}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.industry && member.industry.length">
                                        <div class="card-item md-subheading mt-30">Industry</div>
                                        <div>{{member.industry.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.bio">
                                        <div class="card-item md-subheading mt-30">Bio</div>
                                        <div>{{member.bio}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.bioKeywords && member.bioKeywords.length">
                                        <div class="card-item md-subheading mt-30">Bio Keyword</div>
                                        <div>{{member.bioKeywords.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.linkedIn">
                                        <div class="card-item md-subheading mt-30">LinkedIn URL</div>
                                        <div>{{member.linkedIn}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.skills && member.skills.length">
                                        <div class="card-item md-subheading mt-30">Skills & Expertise</div>
                                        <div>{{member.skills.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.interests && member.interests.length">
                                        <div class="card-item md-subheading mt-30">Interests</div>
                                        <div>{{member.interests.join(', ')}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.marketingOptIn">
                                        <div class="card-item md-subheading mt-30">Marketing Opt-In?</div>
                                        <div>{{member.marketingOptIn}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.race">
                                        <div class="card-item md-subheading mt-30">Race</div>
                                        <div>{{member.race}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.gender">
                                        <div class="card-item md-subheading mt-30">Gender</div>
                                        <div>{{member.gender}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.age">
                                        <div class="card-item md-subheading mt-30">Age</div>
                                        <div>{{member.age}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.calendar">
                                        <div class="card-item md-subheading mt-30">Online Calendar URL</div>
                                        <div>{{member.calendar}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.twitter">
                                        <div class="card-item md-subheading mt-30">Twitter URL</div>
                                        <div>{{member.twitter}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.engagementPoints">
                                        <div class="card-item md-subheading mt-30">Engagement Points</div>
                                        <div>{{member.engagementPoints}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.requestStatus">
                                        <div class="card-item md-subheading mt-30">Approved for Requests?</div>
                                        <div class="capitalize">{{member.requestStatus}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.responseStatus">
                                        <div class="card-item md-subheading mt-30">Approved for responses?</div>
                                        <div class="capitalize">{{member.responseStatus}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.termsAccepted">
                                        <div class="card-item md-subheading mt-30">Terms Accepted</div>
                                        <div class="capitalize">{{member.termsAccepted | momentDate}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0">
                                        <div class="card-item md-subheading mt-30">Frequency</div>
                                        <div v-if="member.frequency">{{ toTitleCase(member.frequency) }}</div>
                                        <div v-else>Once A Week</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.engagement">
                                        <div class="card-item md-subheading mt-30">Engagement</div>
                                        <div>{{member.engagement}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.capacity">
                                            <div class="card-item md-subheading mt-30">Capacity</div>
                                            <div>{{member.capacity}}</div>
                                        </div>
                                        <div class="member-detail-flex px-0" v-if="member.propensity">
                                            <div class="card-item md-subheading mt-30">Propensity</div>
                                            <div>{{member.propensity}}</div>
                                        </div>
                                    <div class="member-detail-flex px-0" v-if="member.instagram">
                                        <div class="card-item md-subheading mt-30">Instagram URL</div>
                                        <div>{{member.instagram}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.skypeID">
                                        <div class="card-item md-subheading mt-30">Skype ID</div>
                                        <div>{{member.skypeID}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.school1">
                                        <div class="card-item md-subheading mt-30">School 1</div>
                                        <div>{{member.school1}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.department1">
                                        <div class="card-item md-subheading mt-30">Department 1</div>
                                        <div>{{member.department1}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.major1">
                                        <div class="card-item md-subheading mt-30">Major 1</div>
                                        <div>{{member.major1}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.degree1">
                                        <div class="card-item md-subheading mt-30">Degree 1</div>
                                        <div>{{member.degree1}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.year1">
                                        <div class="card-item md-subheading mt-30">Year 1</div>
                                        <div>{{member.year1}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.school2">
                                        <div class="card-item md-subheading mt-30">School 2</div>
                                        <div>{{member.school2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.department2">
                                        <div class="card-item md-subheading mt-30">Department 2</div>
                                        <div>{{member.department2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.major2">
                                        <div class="card-item md-subheading mt-30">Major 2</div>
                                        <div>{{member.major2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.degree2">
                                        <div class="card-item md-subheading mt-30">Degree 2</div>
                                        <div>{{member.degree2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.year2">
                                        <div class="card-item md-subheading mt-30">Year 2</div>
                                        <div>{{member.year2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.school3">
                                        <div class="card-item md-subheading mt-30">School 3</div>
                                        <div>{{member.school3}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.department3">
                                        <div class="card-item md-subheading mt-30">Department 3</div>
                                        <div>{{member.department3}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.major3">
                                        <div class="card-item md-subheading mt-30">Major 3</div>
                                        <div>{{member.major3}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.degree3">
                                        <div class="card-item md-subheading mt-30">Degree 3</div>
                                        <div>{{member.degree3}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.year3">
                                        <div class="card-item md-subheading mt-30">Year 3</div>
                                        <div>{{member.year3}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.school4">
                                        <div class="card-item md-subheading mt-30">School 4</div>
                                        <div>{{member.school4}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.department4">
                                        <div class="card-item md-subheading mt-30">Department 4</div>
                                        <div>{{member.department4}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.major4">
                                        <div class="card-item md-subheading mt-30">Major 4</div>
                                        <div>{{member.major4}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.degree4">
                                        <div class="card-item md-subheading mt-30">Degree 4</div>
                                        <div>{{member.degree4}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.year4">
                                        <div class="card-item md-subheading mt-30">Year 4</div>
                                        <div>{{member.year4}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.school5">
                                        <div class="card-item md-subheading mt-30">School 5</div>
                                        <div>{{member.school5}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.department5">
                                        <div class="card-item md-subheading mt-30">Department 5</div>
                                        <div>{{member.department5}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.major5">
                                        <div class="card-item md-subheading mt-30">Major 5</div>
                                        <div>{{member.major5}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.degree5">
                                        <div class="card-item md-subheading mt-30">Degree 5</div>
                                        <div>{{member.degree5}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.year5">
                                        <div class="card-item md-subheading mt-30">Year 5</div>
                                        <div>{{member.year5}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.imageURL">
                                        <div class="card-item md-subheading mt-30">Image URL</div>
                                        <div>{{member.imageURL}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.website">
                                        <div class="card-item md-subheading mt-30">Website URL</div>
                                        <div>{{member.website}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.facebook">
                                        <div class="card-item md-subheading mt-30">Facebook URL</div>
                                        <div>{{member.facebook}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.companyStage">
                                        <div class="card-item md-subheading mt-30">Company Stage</div>
                                        <div>{{member.companyStage}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.annualRevenue">
                                        <div class="card-item md-subheading mt-30">Annual Revenue</div>
                                        <div>{{member.annualRevenue}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.teamSize">
                                        <div class="card-item md-subheading mt-30">Team Size</div>
                                        <div>{{member.teamSize}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.wordPressLink">
                                        <div class="card-item md-subheading mt-30">WordPress Link</div>
                                        <div>{{member.wordPressLink}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.customer_custom1">
                                        <div class="card-item md-subheading mt-30">Custom Value 1</div>
                                        <div>{{member.customer_custom1}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.customer_custom2">
                                        <div class="card-item md-subheading mt-30">Custom Value 2</div>
                                        <div>{{member.customer_custom2}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.customer_custom3">
                                        <div class="card-item md-subheading mt-30">Custom Value 3</div>
                                        <div>{{member.customer_custom3}}</div>
                                    </div>
                                    <div class="member-detail-flex px-0" v-if="member.account_owner && member.account_owner.length">
                                        <div class="card-item md-subheading mt-30">Account Owner</div>
                                        <div>{{member.account_owner.join(', ')}}</div>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row v-if="editMemberDetailStatus" class="ml-38"> 
                                <b-col md="12">
                                    <update-member-details />
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
            <b-row id="requestTable">
                <b-col md="12">
                    <b-card>
                        <b-card-body>
                            <h3>Requests Made by Member</h3>
                            <v-client-table v-bind:data="requests" v-bind:columns="requestColumns" :options="requestOptions" id="dataTable" class="member-request-table">
                                <span slot="createdAt" slot-scope="props">
                                    {{ props.row.createdAt | momentDate}}
                                </span>
                                <span slot="subject" slot-scope="props" @click="redirectUrl(props.row.requestId)" class="display-link">
                                    {{ props.row.subject}}
                                </span>
                                <span slot="totalGives" slot-scope="props">
                                    {{ props.row.totalGives }}
                                </span>
                                <span slot="status" slot-scope="props" :class="[{'badge': props.row.status}, {'badge-success': setStatus(props.row.status) == 'New'}, {'badge badge-primary': setStatus(props.row.status) == 'Active'}, {'badge-warning': setStatus(props.row.status) == 'In Review' || setStatus(props.row.status) == 'Pending'}, {'badge-primary-light': setStatus(props.row.status) == 'Closed'}, {'badge badge-primary': setStatus(props.row.status) == 'Tag' || setStatus(props.row.status) == 'Matching' || setStatus(props.row.status) == 'Reviewed'}, {'badge-danger': setStatus(props.row.status) == 'Rejected'}]">
                                    {{props.row.status ? setStatus(props.row.status) : 'N/A'}}
                                </span>
                            </v-client-table>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row id="responseTable">
                <b-col md="12">
                    <b-card>
                        <b-card-body>
                            <h3>Responses Shared by Member</h3>
                            <v-client-table v-bind:data="responses" v-bind:columns="responseColumns" :options="responseOptions" id="dataTable" class="member-response-table">
                                <span slot="responseDate" slot-scope="props">
                                    {{ props.row.responseDate | momentDate}}
                                </span>
                                <span slot="subject" slot-scope="props" @click="redirectUrl(props.row.requestId)" class="display-link">
                                    {{ props.row.subject}}
                                </span>
                                <!-- <span slot="Type of Response" slot-scope="props">
                                    {{ props.row.helpAction}}
                                </span> -->
                                <span slot="responseType" slot-scope="props">
                                    {{ props.row.responseType }}
                                </span>
                                <span slot="status" slot-scope="props" class="text-center" :class="[{'badge': props.row.status}, {'badge-success': setResStatus(props.row.status) == 'Accepted'}, {'badge-danger': setResStatus(props.row.status) == 'Declined'}, {'badge-blue': setResStatus(props.row.status) == 'Snoozed'}, {'badge-warning': setResStatus(props.row.status) == 'In Review' || setResStatus(props.row.status, props.row) == 'Pending'}]">
                                    {{props.row.status ? setResStatus(props.row.status) : 'N/A'}}
                                </span>
                            </v-client-table>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row id="commentView">
                <b-col md="12">
                    <b-card>
                        <b-card-body>
                            <h3>Comments <span v-text="`(${comments.length})`"></span></h3>
                                <b-table id="comments" hover :items="comments" :fields="commentColumns">
                                <template v-slot:cell(when)="row">
                                    <span>
                                        {{row.item.when | momentDate}}
                                    </span>
                                </template>
                            </b-table>
                            <div id="commentForm">
                                <b-form v-on:submit.prevent="addComment" v-if="addCommentFlag">
                                    <b-form-group label="" label-for="description">
                                        <b-form-textarea
                                        name="description"
                                        v-model="comment.description"
                                        rows="3"
                                        max-rows="6"
                                        v-validate="{ required: true, max:60 }"
                                        placeholder="Enter your comment">
                                    </b-form-textarea>
                                </b-form-group>
                                <b-button type="submit" :disabled="!comment.description" variant="primary tab-focus">Submit</b-button>
                                </b-form>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-card>
                        <v-client-table v-bind:data="memberActivity" v-bind:columns="columns" :options="options" id="dataTable" class="member-detail-table">
                            <span slot="request" v-if="props.row.requestId" @click="redirectUrl(props.row.requestId)" class="display-link" slot-scope="props">
                                {{ props.row.request}}
                            </span>
                            <span slot="request" v-else>{{ props.row.request}}</span>
                            <span slot="date" slot-scope="props" v-if="props.row.date">
                                {{ props.row.date | momentDate }}
                            </span>
                            <span slot="date" v-else>N/A</span>
                            <span slot="type" slot-scope="props" v-if="props.row.type" :class="[{'badge badge-primary': props.row.type == 'Request'}, {'badge badge-success': props.row.type == 'Response'}, {'badge badge-warning': props.row.type == 'Email' || props.row.type == 'Referral'}, {'badge badge-danger': props.row.type == 'Feedback'}, {'badge badge-info': props.row.status == 'updateProfile'}, {'badge badge-danger': props.row.status == 'followedUp'}, {'badge badge-success': props.row.status == 'thankYou'}, {'badge badge-danger': props.row.status == 'optOut'},{'badge badge-resource': props.row.type == 'Resource'}]">
                                {{props.row.type}} 
                            </span>
                            <span slot="title" v-if="props.row.title" @click="redirectUrl(props.row.requestId)" class="display-link" slot-scope="props">
                                {{ props.row.title}}
                            </span>
                        </v-client-table>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <b-modal id="email-modal" class="email-modal" size="md" v-model="emailModal" ok-title="Close" @ok="emailModal = false" title="Update Email">
            <b-card-body>
                <b-form v-on:submit.prevent="updateEmailSubmit">
                    <b-form-group
                        label="Email:"
                        label-for="Email"
                        >
                        <b-form-input
                        type="text"
                        name="email"
                        v-model="updateEmail"
                        v-validate="{ required: true, max:100, email: true }"
                        :state="validateState('email')"
                        placeholder="Enter email"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback v-if="errors.has('email')">
                        {{errors.first('email')}}
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-button type="submit" variant="primary">Update</b-button>
            </b-form>
        </b-card-body>
    </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import VueHtml2pdf from 'vue-html2pdf';
import { Switch as cSwitch } from '@coreui/vue';
import { setRequestStatus } from '@/assets/js/set-status';
import { setResponseStatus } from '@/assets/js/set-status';
import UpdateMemberDetails from './UpdateMemberDetails.vue';


export default {
    name: 'MemberDetails',
    components: {
        VueHtml2pdf,
        cSwitch,
        UpdateMemberDetails
    },
    data() {
        return {
            member: {},
            community: {},
            feedback: 0,
            memberActivity: [],
            columns: ['date', 'type', 'typeDetail', 'title', 'points'],
            token: localStorage.getItem('token'),
            options: {
              sortable: [ 'date', 'type', 'typeDetail', 'title', 'points'],
              sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
              headings:{
                  typeDetail:'Type Detail'
                  },
              pagination: {
                  chunk: 10,
                  nav: 'fixed'
              }
            },
            group: '',
            emailModal: false,
            updateEmail: '',
            memberEmailOfId: '',
            communityId: '',
            htmlToPdfOptions: {
                margin: [0.10, 0, 0.10, 0],
                filename: `${this.$route.params.member_id}.pdf`,
                image: {
                    type: 'jpeg',
                    quality: 2
                },
                enableLinks: false,
                html2canvas: {
                    scale: 1,
                    useCORS: true
                },
                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'landscape'
                }
            },
            comments: [],
            requests: [],
            responses: [],
            commentCount: 0,
            addCommentFlag: false,
            commentColumns: [{key: 'memberName', label: 'Member', sortable: true}, {key: 'when', label: 'Date', sortable: true}, {key: 'comment', label: 'Comment', sortable: true}],
            requestColumns: ['createdAt', 'subject', 'totalGives', 'status'],
            responseColumns: ['responseDate', 'subject', 'responseType', 'status'],
            requestOptions: {
                headings: {
                    totalGives: 'Total Answer Received',
                    createdAt: 'Date'
                },
                sortable: ['createdAt', 'subject', 'totalGives', 'status'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            },
            responseOptions: {
                headings: {
                    responseType: 'Response Type',
                    responseDate: 'Date'
                },

              sortable: ['responseDate', 'subject', 'responseType', 'status'],
              sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
              pagination: {
                  chunk: 10,
                  nav: 'fixed'
              }
            },
            comment: {},
            authUser: {},
            renderUnsubscribe: false,
            editMemberDetailStatus: false,
        }
    },
    async created() {
        this.loadData();
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
    },
    methods: {
        loadData: async function() {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/members/${this.$route.params.member_id}`).then(response => {
                this.member = response.data;
                this.getComment(this.member.comments);
                this.getRequests(this.member._id);
                this.getResponses(this.member._id);
                if (this.member.groups.length) {
                    this.group = this.member.groups.toString();
                }
                if(this.member.unsubscribe) {
                    this.renderUnsubscribe = true;
                }
            });
            if(this.member && this.member.community) {
                await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/communities/id/${this.member.community}`).then(response => {
                    this.community = response.data;
                });
            }
            await this.axios.get(process.env.VUE_APP_API_BASE_URL+ `/dashboard/member_activity/${this.$route.params.member_id}`).then(memberActivity => {
                this.memberActivity = memberActivity.data.activityData;
            });
            this.$helpers.setTabindexOnTableCells();
            this.setAttributesToCswitch();
        },
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        toTitleCase(str) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },
        redirectUrl: function(askId) {
            window.open((`/engagement/requests/${askId}`), '_blank');
        },
        unsubscribeMember: async function(memberId, email, communityId) {
            if (communityId) {
                const unsubscribe = {
                    unsubscribe:
                    {
                        email: email.toString(),
                        reason: '',
                        description: ''
                    },
                    community: communityId,
                    memberId: memberId,
                    isMailSent: false
                }
                await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/members/unsubscribe`, unsubscribe).then(async response => {
                    this.loadData();
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            } else {
                this.showErrorMsg({message: 'Community not assigned'});
            }
        },
        subscribeMember: async function(memberId, email, communityId) {
            if (communityId) {
                const data = {
                    memberId: memberId,
				    email: email, 
				    communityId: communityId
			    }
                await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/members/subscribe`, data).then(async response => {
                    this.loadData();
                    this.renderUnsubscribe = false;
                }).catch(err => {
                    if (err.response.status  === 401) {
                        localStorage.removeItem('authUser');
                        this.$router.push('/login');
                    }
                });
            } else {
                this.showErrorMsg({message: 'Community not assigned'});
            }
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        openEmailModal: function(email, memberId, communityId) {
            this.emailModal = true;
            this.updateEmail = '';
            this.memberEmailOfId = memberId;
            this.communityId = communityId;
        },
        updateEmailSubmit() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    let memberObj = {
                        email: this.updateEmail,
                        memberId: this.memberEmailOfId,
                        communityId: this.communityId
                    }
                    await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/members/update-email`, memberObj, {headers: {'x-access-token': this.token}}).then(async response => {
                        this.emailModal = false;
                        this.loadData();
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            })
        },
        getComment: function(comments) {
            if (comments) {
                comments = this.$lodash.orderBy(comments, ['when'], ['desc'])
                if (this.authUser.role == 'Admin') {
                    comments = this.$lodash.filter(comments, function(o) { return o.commentBy != 'SuperAdmin'; })
                }
                this.comments = comments;
                this.commentCount = comments.length;
            }
        },
        async getRequests(memberId) {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/dashboard/member/get-asks/${memberId}`, {headers: {'x-access-token': this.token}}).then(response => {
                if (response.data) {
                    this.requests = response.data;
                }
            });
        },
        async getResponses(memberId) {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/dashboard/member/get-responses/${memberId}`, {headers: {'x-access-token': this.token}}).then(response => {
                if (response.data) {
                    this.responses = response.data;
                }
            });
        },
        setStatus: function(status) {
            return setRequestStatus(status);
        },
        setResStatus: function(status) {
          return setResponseStatus(status);
        },
        openComment: function() {
            this.addCommentFlag = true;
            setTimeout(function () {
                const elmnt = document.getElementById('commentView');
                elmnt.scrollIntoView();
            }, 0);
        },
        addComment: async function() {
            if (this.comment.description && this.comment.description.length) {
                this.comment.member_id = this.$route.params.member_id;
                await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/members/comment`, this.comment, { headers: {'x-access-token': this.token}}).then(async response => {
                    this.comment = {};
                    if (response.data.comments) {
                        this.getComment(response.data.comments);
                    }
                });
            }
        },
        async toggleMemberFlag() {
            this.comment.member_id = this.$route.params.member_id;
            await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/toggle-member-flag/${this.member._id}`, { headers: {'x-access-token': this.token}}).then(async response => {
                if (response.data.success && response.data.memberFlag) {
                    this.member.memberFlag = response.data.memberFlag;
                    if (response.data.memberRank) {
                        this.member.member_rank = response.data.memberRank;
                    }
                }
            });
        },
        changeMemberDetailsStatus: function(r) {
            console.log("this.editMemberDetailStatus: ", this.editMemberDetailStatus);
            this.setAttributesToCswitch();
            this.loadData();
            
        }, 
        setAttributesToCswitch() {
            const Cswitch = document.querySelectorAll('.edit-member-details-switch .switch-slider');
            const keypressHandler = function(event) {
                if (event.key === 'Enter') {
                    this.editMemberDetailStatus = !this.editMemberDetailStatus;
                    this.changeMemberDetailsStatus();
                }
            };

            for (let i = 0; i < Cswitch.length; i++) {
                const switchElement = Cswitch[i];
                switchElement.removeEventListener('keypress', switchElement.keypressHandler);
                const boundKeypressHandler = keypressHandler.bind(this);
                switchElement.addEventListener('keypress', boundKeypressHandler);
                switchElement.keypressHandler = boundKeypressHandler;
                switchElement.setAttribute('role', 'switch');
                switchElement.setAttribute('tabindex', '0');

                if (this.editMemberDetailStatus) {
                    switchElement.setAttribute('aria-checked', 'true');
                } else {
                    switchElement.setAttribute('aria-checked', 'false');
                }
            }
        }
    }
}
</script>
