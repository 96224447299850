const moment = require('moment-timezone');
import { convertArrayToCSV } from 'convert-array-to-csv'
import { setRequestStatus, setResponseStatus } from './set-status';

/*
    used to export and download csv file into a javascript array
    first parameter is an javascript array: required field
    second parameter is a file name: required field
*/
export async function exportCSV(selectedArray, filename, excludeColumns = [], dateFields = []) {
    if(!Array.isArray(selectedArray)) {
        return;
    }
    if(filename.length == 0) {
        return;
    }
    if(Array.isArray(selectedArray) && selectedArray.length == 0) {
        return;
    }
    if(!Array.isArray(dateFields)) {
        return;
    }
    var keys = await Object.keys(selectedArray[0]).filter(key => {
        if(excludeColumns.length) {
            if(excludeColumns.indexOf(key) == -1) {
                return key;
            }
        } else {
            return key;
        }
    });
    keys = keys.filter(key => key !== 'memberRel')
    if (filename == 'engagement'){
        keys = keys.concat(["member_role", "capacity", "propensity", "engagement"])
        var data = keys.map((key, index) => {
            if (key == 'requestId') {
                keys = array_move(keys, index, 3);
            }
            if (key == 'tags') {
                keys = array_move(keys, index, keys.length-1);
            }
            if (key == 'member_role') {
                keys = array_move(keys, index, 7)
            }
            if (key == 'capacity') {
                keys = array_move(keys, index, 8)
            }
            if (key == 'propensity') {
                keys = array_move(keys, index, 9)
            }
            if (key == 'engagement') {
                keys = array_move(keys, index, 10)
            }
        });
    }
    if (filename == 'interaction') {
        for (var i = 0; i < excludeColumns[1]; i++) {
            let j = i+1;
            keys.push('response'+j);
            keys.push('response_date'+j);
            keys.push('advisor_email'+j);
            keys.push('property_id'+j);
            keys.push('feedback_score'+j);
            keys.push('feedback'+j);
            keys.push('open'+j);
            keys.push('click'+j);
            keys.push('thankYou'+j);
            keys.push('followUp'+j);
        }
    }

    if (filename == 'referrals') {
        if (!keys.includes('title')) {
            keys.push('title');
        }
        if (!keys.includes('circle')) {
            keys.push('circle');
        }
        if (!keys.includes('text')) {
            keys.push('text');
        }
    }

    // if (filename == 'responses') {
    //     keys = array_move(keys, 2, 0)
    // }
    // var a = selectedArray.sort();
    var values = selectedArray.map(value => {
        
        if (filename == 'engagement') {
            value['member_role'] = value.memberRel && value.memberRel.member_role ? value.memberRel.member_role : '';
            value['capacity'] = value.memberRel && value.memberRel.capacity ? value.memberRel.capacity : '';
            value['propensity'] = value.memberRel && value.memberRel.propensity ? value.memberRel.propensity : '';
            value['engagement'] = value.memberRel && value.memberRel.engagement ? value.memberRel.engagement : '';

        }

        if (filename == 'interaction') {
            for (var i = 0; i < value.responses.length; i++) {
                let j = i+1;
                let response_key = 'response'+j;
                let response_date_key = 'response_date'+j;
                let advisor_email_key = 'advisor_email'+j;
                let property_id_key = 'property_id'+j;
                let feedback_score_key = 'feedback_score'+j;
                let feedback_key = 'feedback'+j;
                let open_key = 'open'+j;
                let click_key = 'click'+j;
                let thankYou_key = 'thankYou'+j;
                let followUp_key = 'followUp'+j;
                value[response_key] = value.responses[i].response1;
                value[response_date_key] = value.responses[i].response_date;
                value[advisor_email_key] = value.responses[i].advisor_email;
                value[property_id_key] = value.responses[i].property_id;
                value[feedback_score_key] = value.responses[i].feedback_score;
                value[feedback_key] = value.responses[i].feedback;
                value[open_key] = value.responses[i].open;
                value[click_key] = value.responses[i].click;
                value[thankYou_key] = value.responses[i].thankYou;
                value[followUp_key] = value.responses[i].followUp;
            }
        }
        var data = keys.map((key, index) => {
            if(key == 'status') {
                if (filename === 'responses') {
                    if (value.isReferralGive === 'referred') {
                        value[key] = 'Referred';
                    } else {
                        value[key] = setResponseStatus(value[key]);
                    }
                } else {
                    value[key] = setRequestStatus(value[key]);
                }
            }
            if(filename != 'donor-engagement' && filename != 'referrals') {
                if((typeof value[key] == 'undefined' || value[key].length == 0) && dateFields.includes(key) == false) {
                    value[key] = 'N/A';
                }
            }
            if (dateFields.includes(key)) {
                return value[key] && value[key].length ? moment(value[key]).tz('America/New_York').format('MM/DD/YYYY HH:mm') : 'N/A';
            } else {
                if (typeof value[key] == 'number') {
                    return value[key];
                } else {
                    let REGEX_JAPANESE = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;
                    let REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
                    if (REGEX_JAPANESE.test(value[key]) || REGEX_CHINESE.test(value[key])) {
                        value[key] = 'N/A';
                    }
                    let valuePera = value[key] ? value[key].toString() : "";
                    return valuePera.replace(/•/g, "-").replace(/•\t/g, "  ").replace("™", "");
                }
            }
        });
        return data;
    });

    if(filename == 'resources') {
        // const desiredOrder = ['name', 'description', 'type', 'roles', 'clicks', 'community', 'circle'];
        keys = keys.map(key => key === 'countOfClicks' ? 'clicks' : key);
        // keys = desiredOrder.filter(key => keys.includes(key)).concat(keys.filter(key => !desiredOrder.includes(key)));
        // values = values.map(row => {
        //     let reorderedRow = [];
        //     keys.forEach(key => {
        //         reorderedRow.push(row[key]);
        //     });
        //     return reorderedRow;
        // });
    }
    values.unshift(keys);
    let timestamp = moment(new Date()).format('DD_MM_YYYY_ss');
    let csvContent = await convertArrayToCSV(values, {keys, separator: ','});
    if(csvContent.length) {
        const url = window.URL.createObjectURL(new Blob([csvContent]))
		const link = document.createElement('a')
		link.href = url
        console.log("link is : ", link);
		link.setAttribute('download', `${filename}-${timestamp}.csv`) //or any other extension
		document.body.appendChild(link)
		link.click()
        // downloadCSV({filename: `${filename}-${timestamp}.csv`, data: csvContent});
        return true;
    }
}

// Move an array element from array position
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

function downloadCSV(args) {
    var data, filename, link;
    var csv = args.data;
    if (csv == null) return;

    filename = args.filename || 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
}

export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}
