<template>
    <div class="mb-50">
        <div class="request-status-block">
            <ul class="list-inline">
                <li class="list-inline-item detail-inline-item">
                    <h1> Request <!-- <span v-if="ask.status">{{setStatus(ask.status)}}</span> --> </h1>
                    <span v-if="ask.favorite">
                        <button type="button" class="btn custom-card-header-filter btn-transparent btn:focus"
                        @click="favoriteRequest(ask._id, 'unfavorite')"
                        v-b-tooltip.hover.bottom title="favorite" >
                            <i class="fa fa-star fa-lg gold-color" aria-hidden="true"></i>
                        </button>
                    </span>
                    <span v-else>
                        <button type="button" class="btn custom-card-header-filter btn-transparent btn:focus"
                        @click="favoriteRequest(ask._id,  'favorite')"
                        v-b-tooltip.hover.bottom title="unfavorite" >
                        <i class="fa fa-star-o fa-lg" aria-hidden="true"></i></button>
                    </span>
                </li>
                <li class="list-inline-item float-right">
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <b-dropdown class="float-right" v-b-tooltip.hover.bottom title="Show options" variant="btn btn-dropbox btn-sm tab-focus" right>
                                <template slot="button-content">
                                    <i class="icon-settings"></i>
                                </template>
                                <b-dropdown-item  v-if="setStatus(ask.status) == 'Active' || setStatus(ask.status) == 'Closed'" @click="$router.push(`/engagement/requests/${$route.params.request_id}/story`)">Story mode
                                </b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) !== 'Closed' && setStatus(ask.status) !== 'Rejected' && setStatus(ask.status) !== 'Pending'" @click="redirectToCoachPage(ask._id)">Coach and close</b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) == 'Active'" @click="openRequestActionUrl(ask._id)">View Action Page
                                </b-dropdown-item>
                                <b-dropdown-item   v-if="setStatus(ask.status) == 'Active'" @click="opnAddAdvisorModal()">Add Advisor</b-dropdown-item>
                                <b-dropdown-item @click="showResponseDialog(ask._id)">Add response</b-dropdown-item>
                                <b-dropdown-item v-if="setStatus(ask.status) == 'Active'" @click="shareRequestModal()">Email request
                                </b-dropdown-item>
                                <b-dropdown-item v-if="setStatus(ask.status) == 'Active'" @click="referRequest(ask._id, createdByMember._id)">Refer request
                                </b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) !== 'Closed' && setStatus(ask.status) !== 'Rejected'" @click="rejectRequest(ask._id)">Reject the request</b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) == 'Closed' || setStatus(ask.status) == 'Rejected'" @click="reOpenRequest(ask._id)">Reopen the request
                                </b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) == 'In Review'" @click="approveRequest(ask._id, 'approve', 'no')">Approve request</b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) !== 'Closed' && setStatus(ask.status) !== 'Rejected'" @click="closeRequest(ask._id)">Close the request
                                </b-dropdown-item>
                                <b-dropdown-item  v-if="setStatus(ask.status) == 'Active' && (authUser.memberID || authUser.role =='SuperAdmin') || setStatus(ask.status) == 'In Review'"@click="openComment()" class="comment-info">
                                    Comment<span class="count" v-if="commentCount">{{commentCount}}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </li>
                        <!-- <li class="list-inline-item" v-if="setStatus(ask.status) == 'Active' || setStatus(ask.status) == 'Closed'">
                            <button type="button" class="btn custom-card-header-filter btn-dropbox" @click="$router.push(`/engagement/requests/${$route.params.request_id}/story`)" v-b-tooltip.hover.bottom title="Story mode" >
                                <i class="cil-heart"></i>
                            </button>
                        </li>
                        <li class="list-inline-item" v-if="setStatus(ask.status) == 'Active' && (authUser.memberID || authUser.role =='SuperAdmin') || setStatus(ask.status) == 'In Review'">
                            <button type="button" class="btn custom-card-header-filter btn-dropbox notification-btn" @click="openComment()" v-b-tooltip.hover.bottom title="Comment" >
                                <span class="count" v-if="commentCount">{{commentCount}}</span>
                                <i class="cil-comment-square"></i>
                            </button>
                        </li>
                        <li class="list-inline-item" v-if="setStatus(ask.status) == 'Active'">
                            <button type="button" class="btn custom-card-header-filter btn-dropbox" @click="openRequestActionUrl(ask._id)" v-b-tooltip.hover.bottom title="View Action Page" ><i class="cui-share"></i></button>
                        </li>
                        <li class="list-inline-item" v-if="setStatus(ask.status) == 'Active'">
                            <button type="button" class="btn custom-card-header-filter btn-dropbox" @click="shareRequestModal(ask._id)" v-b-tooltip.hover.bottom title="Share the request" ><i class="cil-share-alt"></i></button>
                        </li>
                        <li class="list-inline-item" v-if="setStatus(ask.status) !== 'Closed'">
                            <button type="button" class="btn custom-card-header-filter btn-dropbox" v-b-tooltip.hover.bottom title="Close the request" @click="closeRequest(ask._id)"  ><i class="fa fa-ban"></i></button>
                        </li> -->
                    </ul>
                </li>
            </ul>
        </div>
        <b-modal id="add-advisor-modal" class="add-advisor-modal" size="md" v-model="addAdvisorModal" ok-title="Close" @ok="addAdvisorModal = false" title="Add Advisor">
            <b-card-body>
                <b-form  v-on:submit.prevent="addAdvisorEmailSubmit">
                    <b-form-group
                    label="Email:"
                    label-for="advisorEmail"
                    >
                        <b-form-input
                        type="text"
                        name="advisorEmail"
                        v-model="advisorEmail"
                        @keyup="addAdvisorErrMsg = ''"
                        v-validate="{ required: true, max:100, email: true }"
                        :state="validateState('advisorEmail')"
                        placeholder="Enter email"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors.has('advisorEmail')">
                            {{errors.first('advisorEmail')}}
                        </b-form-invalid-feedback>
                        <span class="red" v-if="addAdvisorErrMsg">
                            {{ addAdvisorErrMsg }}
                        </span>
                    </b-form-group>
                    <b-button type="submit" variant="primary tab-focus">Add advisor</b-button>
                </b-form>
            </b-card-body>
            <template #modal-footer="{ cancel }">
                <div>
                    <button class="btn custom-card-header-filter btn-primary tab-focus custom-close-button" @click="cancel()">Close</button>
                </div>
            </template>
        </b-modal>
        <b-modal id="share-request-modal" class="share-request-modal" size="md" hide-footer v-model="showShareRequestModal" title="Add email of recipient">
            <b-card-body>
                <b-form  v-on:submit.prevent="submitShareRequest(recipientEmail)">
                    <b-form-group
                    label="Email:"
                    label-for="recipientEmail"
                    >
                        <b-form-input
                        type="text"
                        name="recipientEmail"
                        id="recipientEmail"
                        v-model="recipientEmail"
                        @keyup="addRecipientEmailErrMsg = ''"
                        v-validate="{ required: true, max:100, email: true }"
                        :state="validateState('recipientEmail')"
                        placeholder="Enter email"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors.has('recipientEmail')">
                            {{errors.first('recipientEmail')}}
                        </b-form-invalid-feedback>
                        <span class="red" v-if="addRecipientEmailErrMsg">
                            {{ addRecipientEmailErrMsg }}
                        </span>
                    </b-form-group>
                    <div class="">
						<b-button type="submit" variant="primary" :disabled="loadingShareRequest" v-if="loadingShareRequest">Share <i class="fa fa-spinner" aria-hidden="true"></i></b-button>
						<b-button type="submit" variant="primary" v-else>Share</b-button>
                        <b-button class="m-5px" variant="secondary" @click="closeShareRequestModal">Close</b-button>
					</div>
                </b-form>
            </b-card-body>
            <template #modal-footer="{ cancel }">
                    <div>
                        <button class="btn custom-card-header-filter btn-primary tab-focus custom-close-button" @click="cancel()">Close</button>
                    </div>
            </template>
        </b-modal>
        <b-modal id="response-modal" class="response-modal" size="lg" hide-footer  v-model="showResponseModal" title="Add Response">
            <b-card-body>
                <b-form v-on:submit.prevent="addAdvisorResponseSubmit('response')" data-vv-scope="response">
					<b-form-group
                    label="Advisor Email:"
                    label-for="resAdvisorEmail"
                    >
                        <b-form-input
                        type="text"
                        name="resAdvisorEmail"
                        v-model="resAdvisorEmail"
                        @keyup="addResAdvisorErrMsg = ''"
                        v-validate="{ required: true, max:100, email: true }"
                        :state="validateState('response.resAdvisorEmail')"
                        placeholder="Enter advisor email"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback v-if="errors.has('response.resAdvisorEmail')">
                            {{errors.first('response.resAdvisorEmail')}}
                        </b-form-invalid-feedback>
                        <span class="red" v-if="addResAdvisorErrMsg">
                            {{ addResAdvisorErrMsg }}
                        </span>
                    </b-form-group>
					<b-form-group label="Response:" label-for="advisorResponse">
                        <li class="list-inline-item w-100">
                            <b-form-textarea
								id="response-text-comment"
								size="lg"
								name="advisorResponse"
								v-model="advisorResponse"
								@keyup="errorAdvisorResponse = ''"
								v-validate="{ required: true }"
								:state="validateState('response.advisorResponse')"
								placeholder="Response from advisor"
							></b-form-textarea>
							<b-form-invalid-feedback v-if="errors.has('response.advisorResponse')">
								{{errors.first('response.advisorResponse')}}
							</b-form-invalid-feedback>
							<span class="red" v-if="errorAdvisorResponse">
								{{ errorAdvisorResponse }}
							</span>
                        </li>
                    </b-form-group>
					<div class="">
						<b-button class="m-5px tab-focus" type="submit" variant="primary">Submit</b-button>
						<b-button class="m-5px tab-focus" variant="secondary" @click="closeResponseDialog">Close</b-button>
					</div>
                </b-form>
            </b-card-body>
        </b-modal>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <div class="ask-detail">
                            <!-- <div class="ask-member-container vtopblock">
                                <ul class="list-inline">
                                    <li>
                                        <img class="member-image" v-if="createdByMember && createdByMember.imageURL" :src="createdByMember.imageURL" alt='Member photo'>
                                        <img class="member-image" v-if="!createdByMember || !createdByMember.imageURL"  src="../../../../assets/person.png" alt='Member photo'>
                                    </li>
                                    <li>
                                        <div class="ask-member-details">
                                            <div class="edit-ask" v-if="createdByMember && createdByMember.first && createdByMember.last"> <span class="display-link" @click="goToProfile(createdByMember._id)">{{ createdByMember.first + ' ' +  createdByMember.last }}</span>
                                            </div>
                                            <div class="edit-ask" v-else-if="createdByMember"> <span class="display-link" @click="goToProfile(createdByMember._id)">{{ createdByMember.displayName }}</span>
                                            </div>
                                            <div class="edit-ask" v-if="createdByMember && createdByMember.first && createdByMember.last">{{createdByMember.emails[0]}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div> -->
                        <div class="inline-grid">
                            <p class="ask-header" v-if="createdByMember && createdByMember.first && createdByMember.last">
                                <span class="display-link" @click="goToProfile(createdByMember._id)">{{ createdByMember.first + ' ' +  createdByMember.last }}</span>
                            </p>
                            <p class="ask-header" v-else-if="createdByMember"> <span class="display-link" @click="goToProfile(createdByMember._id)">{{ createdByMember.displayName }}</span>
                            </p>
                            <span><div class="edit-ask" v-if="createdByMember && createdByMember.first && createdByMember.last">{{createdByMember.emails[0]}}</div></span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Channel</p>
                            <span>{{ask.channelType}}</span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Circle</p>
                            <span>{{ask.sourceparam}}</span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Request Received</p>
                            <span v-if="ask.reqReceivedDate">{{ask.reqReceivedDate | momentDate}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Advisors Asked</p>
                            <span v-if="Array.isArray(ask.recommendedAdvisors) && ask.recommendedAdvisors.length > 0">{{Array.isArray(ask.recommendedAdvisors) ? ask.recommendedAdvisors.length : 0}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Request Status</p>
                            <span v-if="ask.status">{{setStatus(ask.status)}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <!-- <div class="inline-grid">
                            <p class="ask-header">Advice Area</p>
                            <span v-if="ask.advice_area">{{ ask.advice_area }}</span>
                            <span v-else>N/A</span>
                        </div> -->
                        <div class="inline-grid">
                            <p class="ask-header">Request Sent</p>
                            <span v-if="ask.reqSentDate">{{ask.reqSentDate | momentDate}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Responses</p>
                            <span v-if="positiveResponse > 0">{{positiveResponse}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Advisor Feedback</p>
                            <span v-if="advisorFeedback > 0">{{advisorFeedback}}</span>
                            <span v-else>N/A</span>
                        </div>
                        <div class="inline-grid">
                            <p class="ask-header">Member Feedback</p>
                            <span v-if="memberFeedback > 0">{{memberFeedback}}</span>
                            <span v-else>N/A</span>
                        </div>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Request</h3>
                        <label class="ask-header" for="subject" aria-label="Subject">Subject</label>
                        <p v-text="ask.subject" v-if="setStatus(ask.status) == 'Closed' || setStatus(ask.status) == 'Rejected'"></p>
                        <div v-else >
                            <input name="subject" id="subject" @blur="UpdateAskDetail('subject')" type="text" placeholder="Enter subject" v-model.trim="ask.subject" class="form-control">
                            <p></p>
                        </div>
                        <label class="ask-header" for="text" aria-label="Description">Description</label>
                        <p class="md-justify" v-text="ask.text" v-if="setStatus(ask.status) == 'Closed' || setStatus(ask.status) == 'Rejected'"></p>
                        <b-form-textarea name="text" id="text" @blur="UpdateAskDetail('text')" placeholder="Enter description" rows="14" v-model.trim="ask.text" v-else class="form-control md-justify"></b-form-textarea>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Tags <span v-text="`(${$lodash.has(ask.tags, 0) ? ask.tags.length : '0'})`"></span></h3>
                        <div class="flex-box" v-if="Array.isArray(ask.tags) && ask.tags.length">
                            <template v-for="tag in ask.tags">
	      						<div tabindex="0" class="flex-child md-style-chip tab-focus" :key="tag" @click="tagRequest(tag)" @keypress.enter="tagRequest(tag)"> {{tag}}</div>
	      					</template>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Advisors <span v-text="`(${matchedAdvisors.length})`"></span></h3>
                        <b-table id="advisers" hover :items="matchedAdvisors" :fields="adviserColumns" :sort-by.sync="sortByScore" :sort-desc.synv="sortDescScore" :sort-compare="sortAdvisers">
                            <template v-slot:cell(name)="row">
                                <span class="display-link" @click="goToProfile(row.item._id)" v-if="row.item._id">
                                    {{row.item.name}}
                                </span>
                            </template>
                            <!-- <template v-slot:cell(score)="row">
                                {{row.item.score + '%'}}
                            </template> -->
                            <template v-slot:cell(status)="row">
                                {{getAdviserSectionStatus(row.item._id)}}
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Escalations <span v-text="`(${escalatedAdvisors.length})`"></span></h3>
                        <b-table id="escalated-advisors" hover :items="escalatedAdvisors" :fields="escalatedAdviserColumns" :sort-by.sync="sortByScore" :sort-desc.synv="sortDescScore" :sort-compare="sortAdvisers">
                            <template v-slot:cell(name)="row">
                                <span class="display-link" @click="goToProfile(row.item._id)" v-if="row.item._id">
                                    {{row.item.name}}
                                </span>
                            </template>
                            <!-- <template v-slot:cell(score)="row">
                                {{row.item.score + '%'}}
                            </template> -->
                            <template v-slot:cell(status)="row">
                                {{getAdviserSectionStatus(row.item._id)}}
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Responses <span v-text="`(${responseCount})`"></span></h3>
                        <b-table id="responses" hover :items="respondedMember" :fields="responseColumns" :sort-compare="sortResponses">
                            <template v-slot:cell(response_name)="row">
                                <span class="display-link" @click="goToProfile(row.item.response_Id)" v-if="row.item.response_Id">
                                    {{row.item.response_name}}
                                </span>
                            </template>
                            <template v-slot:cell(response_email)="row">
                                <span class="display-link" @click="goToProfile(row.item.response_Id)" v-if="row.item.response_Id">
                                    {{row.item.response_email}}
                                </span>
                                <span  v-else>
                                    {{row.item.response_email}}
                                </span>
                            </template>
                            <template v-slot:cell(status)="row">
                                <span :class="[{'md-orange-color': row.item.status === 'In Review' || row.item.status === 'Pending'}, {'md-green-color': row.item.status === 'Approved'}, {'md-red-color': row.item.status === 'Rejected'}, {'md-yellow-color': row.item.giveType && row.item.giveType === 'referred'}, {'md-blue-color': row.item.status === 'Snoozed'},]">
                                    {{ row.item.giveType && row.item.giveType === 'referred' ? 'Referred': getStatus(row.item.status)}}
                                </span>
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Advice <span v-text="`(${advice.length})`"></span></h3>
                        <b-table id="advice" hover :items="advice" :fields="adviceColumns" :sort-compare="sortResponses">
                            <template v-slot:cell(response_name)="row">
                                <span class="display-link" @click="goToProfile(row.item.response_Id)" v-if="row.item.response_Id">{{row.item.response_name}}</span>
                                <span v-else>{{row.item.response_name}}</span>
                            </template>
                            <template v-slot:cell(insight)="row">
                                <span class="white-space">{{row.item.insight}}</span>
                            </template>
                            <template v-slot:cell(when)="row">
                                <span>{{row.item.when | momentDate}}</span>
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Referrals <span v-text="`(${referrals.length})`"></span></h3>
                        <b-table id="advice" hover :items="referrals" :fields="referralColumns">
                            <template v-slot:cell(advisor)="row">
                                <span class="display-link" @click="goToProfile(row.item.advisorId)" v-if="row.item.advisorId">{{row.item.advisor}}</span>
                                <span v-else>{{row.item.advisor}}</span>
                            </template>
                            <template v-slot:cell(when)="row">
                                <span>{{row.item.when | momentDate}}</span>
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Member Follow Up <span v-text="`(${thankYou.length})`"></span></h3>
                        <b-table id="thankYou" hover :items="thankYou" :fields="thankYouColumns">
                            <template v-slot:cell(adviserName)="row">
                                <span class="display-link" @click="goToProfile(createdByMember._id)" v-if="createdByMember">{{ createdByMember.first + ' ' +  createdByMember.last }}</span>
                            </template>
                            <template v-slot:cell(responseComment)="row">
                                <span class="white-space">{{row.item.responseComment}}</span>
                            </template>
                            <template v-slot:cell(thankYouWhen)="row">
                                <span>{{row.item.thankYouWhen | momentDate}}</span>
                            </template>
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Feedback <span v-text="`(${feedebacks.length})`"></span></h3>
                        <b-table hover :items="feedebacks" :fields="feedbackColumns" :sort-compare="sortFeedbacks">
                            <template v-slot:cell(feedback_name)="row">
                                <span class="display-link" @click="goToProfile(row.item.feedback_Id)" v-if="row.item.feedback_Id">{{row.item.feedback_name}}</span>
                                <span slot="feedback_name" slot-scope="props" v-else>{{'N/A'}}</span>
                            </template>
                            <template v-slot:cell(email)="row">
                                <span class="display-link" @click="goToProfile(row.item.feedback_Id)" v-if="row.item.feedback_Id">{{row.item.email}}</span>
                                <span slot="feedback_name" slot-scope="props" v-else>{{row.item.email}}</span>
                            </template>
                            <template v-slot:cell(status)="row">
                                <span :class="[{'md-orange-color': row.item.status === 'In Review'}, {'md-green-color': row.item.status === 'Approved'}, {'md-red-color': row.item.status === 'Rejected'}]">
                                    {{row.item.status}}
                                </span>
                            </template>
                            <template v-slot:cell(feedback)="row">
                                {{row.item.feedback ? row.item.feedback : 'N/A'}}
                            </template>

                            <!-- <span slot="feedback_name" slot-scope="props" class="display-link" @click="goToProfile(props.item.feedback_Id)" v-if="props.item.feedback_Id">
                                {{checkData(props.item.feedback_name)}}
                            </span>
                            <span slot="feedback_name" slot-scope="props" v-else>
                                {{'N/A'}}
                            </span>
                            <span slot="email" slot-scope="props" class="display-link" @click="goToProfile(props.item.feedback_Id)" v-if="props.item.feedback_Id">
                                {{checkData(props.item.email)}}
                            </span>
                            <span slot="email" slot-scope="props" v-else>
                                {{checkData(props.item.email)}}
                            </span>
                            <span slot="received" slot-scope="props">
                                {{props.item.received}}
                            </span>
                            <span slot="sent" slot-scope="props">
                                {{props.item.sent}}
                            </span>
                            <span slot="status" slot-scope="props" :class="[{'md-orange-color': props.item.status === 'In Review'}, {'md-green-color': props.item.status === 'Approved'}, {'md-red-color': props.item.status === 'Rejected'}]">
                                {{props.item.status}}
                            </span>
                            <span slot="feedback" slot-scope="props">
                                {{props.item.feedback ? props.item.feedback : 'N/A'}}
                            </span> -->
                        </b-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" id="commentView">
                <b-card>
                    <b-card-body>
                        <h3>Comments <span v-text="`(${comments.length})`"></span></h3>
                        <b-table id="comments" hover :items="comments" :fields="commentColumns" :sort-by.sync="sortByScore" :sort-desc.synv="sortDescScore" :sort-compare="sortAdvisers">
                            <template v-slot:cell(when)="row">
                                <span>
                                    {{row.item.when | momentDate}}
                                </span>
                            </template>
                        </b-table>
                        <div id="commentForm">
                            <b-form v-on:submit.prevent="addComment" v-if="addCommentFlag">
                                <b-form-group label="" label-for="description">
                                    <b-form-textarea
                                    name="description"
                                    v-model="comment.description"
                                    rows="3"
                                    max-rows="6"
                                    v-validate="{ required: true, max:60 }"
                                    placeholder="Enter your comment">
                                </b-form-textarea>
                            </b-form-group>
                            <b-button type="submit" :disabled="!comment.description" variant="primary tab-focus">Submit</b-button>
                            </b-form>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <b-card>
                    <b-card-body>
                        <h3>Resources <span v-text="`(${resources.length})`"></span></h3>
                        <v-client-table :data="resources" :columns="resourcesColumns" :options="resourcesOptions">
                            <!-- <template v-slot:cell(name)="row">
                                <span class="display-link">{{ row.item.name }}</span>
                            </template>
                            <template v-slot:cell(description)="row">
                                <span>{{ row.item.description }}</span>
                            </template>
                            <template v-slot:cell(roles)="row">
                                <span>{{ row.item.roles.join(', ') }}</span>
                            </template>
                            <template v-slot:cell(clicks)="row">
                                <span>{{ row.item.clicks }}</span>
                            </template> -->
                        </v-client-table>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <!-- <b-row>
            <b-col md="12" id="commentView">
                <b-card>
                    <b-card-body>
                        <h3>Activity <span v-text="`(${escalatedActivityData.length})`"></span></h3>
                        <md-card-content class="comments">
                            <div class="activity-log">
                                <div v-for="(activity, index) in escalatedActivityData" class="activity" :key="activity.action+index" style="margin-bottom: 20px;">
                                    <div>
                                        <b>{{ activity.when | momentDate }} <span v-if="activity.memberName">- {{ activity.memberName }}</span></b>
                                        <div class="ask-description">{{ activity.description }}</div>
                                        <div class="ask-description">{{ activity.details }}</div>
                                    </div>
                                </div>
                            </div>
                        </md-card-content>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row> -->
        <b-row>
    <b-col md="12">
      <b-card>
        <b-card-body>
          <h3>Activity <span v-text="`(${requestActivity.length})`"></span></h3>
          <v-client-table v-bind:data="requestActivity" v-bind:columns="activityColumns" :options="activityOptions" id="dataTable" class="request-activity-table">
            <span slot="date" slot-scope="props" v-if="props.row.date">
              {{ props.row.date | momentDate }}
            </span>
            <span slot="date" v-else>N/A</span>
            <span slot="memberName" v-if="props.row.memberRel._id" @click="goToProfile(props.row.memberRel._id)" class="display-link" slot-scope="props">
                {{ props.row.memberName }}
            </span>
            <span slot="memberName" v-else>
                {{ props.row.memberName }}
            </span>

            <span slot="type" slot-scope="props" v-if="props.row.type" :class="[{'badge badge-primary': props.row.type == 'Request'}, {'badge badge-success': props.row.type == 'Response'}, {'badge badge-warning': props.row.type == 'Email' || props.row.type == 'Referral'}, {'badge badge-danger': props.row.type == 'Feedback'}, {'badge badge-info': props.row.type == 'Update Profile'}, {'badge badge-danger': props.row.type == 'Followed Up'}, {'badge badge-success': props.row.type == 'Thank You'}, {'badge badge-danger': props.row.type == 'Opt Out'},{'badge badge-resource': props.row.type == 'Resource'}]">
              {{ props.row.type }}
            </span>
          </v-client-table>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import { setRequestStatus } from '@/assets/js/set-status';
import VueNotifications from 'vue-notifications'

export default {
    name: 'RequestDetail',
    data() {
        return {
            ask: {},
            community: {},
            createdByMember: {},
            matchedAdvisors: [],
            escalatedAdvisors: [],
            escalatedActivityData: [],
            feedebacks: [],
            respondedMember: [],
            comments: [],
            advice: [],
            referrals: [],
            thankYou: [],
            requestActivity: [],
            resources:[],
            resourcesColumns:['name', 'description', 'roles', 'clicks'],
            resourcesOptions: {
                sortable: ['name', 'description', 'roles', 'clicks'],
                sortIcon: { base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort' },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
            },
            activityColumns: ['date', 'memberName', 'type', 'typeDetail'],
            activityOptions: {
                sortable: ['date', 'memberName', 'type', 'typeDetail'],
                sortIcon: { base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort' },
                headings: {
                    typeDetail: 'Type Detail',
                    memberName: 'Member'
                },
                pagination: {
                    chunk: 10,
                    nav: 'fixed'
                }
      },
            adviserColumns: [{key: 'name', label: 'Name', sortable: true}, {key: 'community', sortable: true}, {key: 'last_request', label: "Request Sent", sortable: true}, {key: 'last_response', label: 'Responded', sortable: true}, {key: 'status', label: 'Email', sortable: true}, {key:'eventData', label:'Status', sortable: true}, {key: 'action', sortable: true}],
            escalatedAdviserColumns: [{key: 'name', label: 'Name', sortable: true}, {key: 'last_request', label: "Request Sent", sortable: true}, {key: 'last_response', label: 'Responded', sortable: true}, {key: 'email', label: 'Email', sortable: true}, {key:'status', label:'Status', sortable: true}, {key: 'typeDetail',label:'Type Detail', sortable: true}, {key: 'action', sortable: true}],
            sortByScore: 'score',
            sortDescScore: true,
            responseColumns: [{key: 'response_name', label: 'Name', sortable: true}, {key: 'response_email', label: 'Email', sortable: true}, 'community', {key: 'responded', sortable: true}, 'status', 'action', {key: 'sent', sortable: true}],
            sortByResponse: 'responded',
            sortDescResponse: true,
            feedbackColumns: [{key: 'feedback_name', label: 'Name', sortable: true}, 'type', , {key: 'received', sortable: true}, {key: 'testimonialConsent', label: 'Consent'}, 'rating', 'feedback'],
            commentColumns: [{key: 'memberName', label: 'Member', sortable: true}, {key: 'when', label: 'Date', sortable: true}, {key: 'comment', label: 'Comment', sortable: true}],
            adviceColumns: [{key: 'response_name', label: 'Name', sortable: true}, {key: 'when', label: 'Responded', sortable: true}, {key: 'insight', label: 'Advice', sortable: true}, {key: 'feedback', label: 'Feedback', sortable: true}],
            thankYouColumns: [{key: 'adviserName', label: 'Name', sortable: true}, {key: 'thankYouWhen', label: 'Date', sortable: true}, {key: 'responseComment', label: 'THANK YOU text', sortable: true}],
            referralColumns: [{key: 'advisor', label: 'Referred From', sortable: true}, {key: 'when', label: 'Date of Referral', sortable: true}, {key: 'referredTo', label: 'Referred To', sortable: true}, {key: 'action', label: 'Action', sortable: true}],
            sortByComment: 'when',
            sortDescComment: true,
            responseCount: 0,
            token: '',
            advisorFeedback: 0,
            memberFeedback: 0,
            positiveResponse: 0,
            commentCount: 0,
            authUser: {},
            comment: {},
            addCommentFlag: false,
            oldSubject: '',
            oldText: '',
            addAdvisorModal: false,
            addAdvisorEmail: '',
            addAdvisorErrMsg: '',
            advisorEmail: '',
            addRecipientEmailErrMsg: '',
            recipientEmail: '',
            showShareRequestModal: false,
            loadingShareRequest: false, 
            showResponseModal: false,
			selectedRequestId: "",
			resAdvisorEmail: '',
			addResAdvisorErrMsg: '',
			advisorResponse: "",
			errorAdvisorResponse: "",
        }
    },
    notifications: {
        showErrorMsg: {
            type: VueNotifications.types.error,
            message: 'This request cannot be shared.'
        },
        showSuccessMsg: {
            type: VueNotifications.types.success,
            message: 'New advisor added successfully.'
        }
    },
    created() {
        const dict = {
            custom: {
                advisorEmail: {
                    required: 'The email field is required.',
                    email: 'Please enter valid email address.'
                },
                recipientEmail: {
                    required: 'The email field is required.',
                    email: 'Please enter valid email address.'
                },
                resAdvisorEmail: {
                    required: 'The email field is required.',
                    email: 'Please enter valid email address.'
                },
                advisorResponse: {
                    required: 'The response field is required.',
                }
            }
        }
        this.$validator.localize('en', dict);
        this.token = localStorage.getItem('token');
        const user = localStorage.getItem('authUser');
        if (user) {
            this.authUser = JSON.parse(user);
        }
        this.loadData();
    },
    methods: {
        loadData: async function() {
            await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/asks/${this.$route.params.request_id}`, {headers: {'x-access-token': this.token}}).then(request => {
                this.ask = request.data;
                this.oldSubject = this.ask.subject;
                this.oldText = this.ask.text;
                if (request.data.comments) {
                    this.getComment(request.data);
                }
            });
            if (this.ask && this.ask.community) {
                this.community =  this.ask.community.name;
                if (this.ask.gives && this.ask.gives.length > 0) {
                    var positivecount = 0;
                    await this.ask.gives.map(singleGive => {
                        if (singleGive.helpaction === 'yes' && singleGive.responseState.currentState === 'Approved') {
                            positivecount++;
                        }
                    });
                    this.positiveResponse = positivecount;
                }
            }
            this.createdByMember = this.ask.createdByMemberID;
            var advList = await this.axios.get(process.env.VUE_APP_API_BASE_URL + `/dashboard/engagement/${this.ask._id}/request-detail`, {headers: {'x-access-token': this.token}});
            if (advList.data.responseCount) {
                this.responseCount = advList.data.responseCount;
            }
            if (advList.data.adviserData) {
                this.matchedAdvisors = advList.data.adviserData;
            }
            if (advList.data.escalatedAdviserData) {
                this.escalatedAdvisors = advList.data.escalatedAdviserData;
            }
            if (advList.data.responseData) {
                this.respondedMember = advList.data.responseData;
            }
            if (advList.data.adviceData) {
                this.advice = this.$lodash.orderBy(advList.data.adviceData, ['when'], ['desc'])
            }
            if (advList.data.referrals) {
                this.referrals = this.$lodash.orderBy(advList.data.referrals, ['when'], ['desc'])
            }
            if (advList.data.thankYou) {
                this.thankYou = this.$lodash.orderBy(advList.data.thankYou, ['thankYouWhen'], ['desc'])
            }
            if (advList.data.feedbackData) {
                this.feedebacks = advList.data.feedbackData;
                var reqTotal = 0;
                var resTotal = 0;
                var reqScore = 0;
                var resScore = 0;
                await this.feedebacks.map(singleFeedbacks => {
                    if (singleFeedbacks.type === 'Request') {
                        reqTotal++;
                        reqScore += singleFeedbacks.rating;
                    } else if (singleFeedbacks.type === 'Response') {
                        resTotal++;
                        resScore += singleFeedbacks.rating;
                    }
                })
                resScore = parseInt(resScore);
                resTotal = parseInt(resTotal);
                reqTotal = parseInt(reqTotal);
                reqScore = parseInt(reqScore);
                this.memberFeedback = Number(parseFloat(resScore/resTotal).toFixed(2));
                this.advisorFeedback = Number(parseFloat(reqScore/reqTotal).toFixed(2));
            }
            this.$helpers.setTabindexOnTableCells();
            if (advList.data.escalatedActivityData) {
                this.escalatedActivityData = advList.data.escalatedActivityData;
            }
            const resourceResponse = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/resources/${this.ask._id}`, {
            headers: { 'x-access-token': this.token }
        });
        this.resources = resourceResponse.data.map(resource => {
            if (resource.roles === 'all_members') {
                resource.roles = 'All Members';
            }
    return resource;
});
        console.log("resources: ", this.resources);
            
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/${this.$route.params.request_id}/activity`, {
                headers: { 'x-access-token': this.token }
            });
            
            response.data.activityData.forEach(activity => {
                if (activity.memberRel === null) {
                    activity.memberName = "Deleted User";
                    activity.memberRel = {
                        _id: null,
                        first: "Deleted",
                        last: "User",
                    };
                }
});
            this.requestActivity = response.data.activityData;
            
        },
       
		getAdviserSectionStatus: function(adviserId) {
			let giveData = [];
			if (this.ask) {
				let give = this.ask.gives.filter(give => {
					if (give.advisors && give.advisors.length && give.advisors[0] === adviserId) {
						return give;
					}
				});
				if (give && give.length) {
					giveData.push(give[give.length - 1]);
				}
				let advisorData = this.ask.advisorFlags.filter(advisorFlag => advisorFlag.advisor === adviserId);
				let feedbackData = this.ask.feedbackAdvisorFlags.filter(feedbackAdvisorFlag => feedbackAdvisorFlag.advisor === adviserId);
				if (advisorData && advisorData.length && advisorData[0].flag === 0 && typeof advisorData[0].firstRequestSent === 'undefined') {
					return '-';
				} else if (advisorData && advisorData.length && advisorData[0].flag === 0 && advisorData[0].firstRequestSent) {
					return 'Requested';
				} else if (advisorData && advisorData.length && advisorData[0].flag === 1) {
					return '1st Reminder';
				} else if (advisorData && advisorData.length && advisorData[0].flag === 2) {
					return '2nd Reminder';
				} else if (!feedbackData.length && advisorData && advisorData.length && advisorData[0].flag === 3 && giveData.length && giveData[0].helpaction === 'yes') {
					return 'Responded';
				} else if (advisorData && advisorData.length && advisorData[0].flag === 5) {
					return 'Reminder';
				} else if (giveData.length && giveData[0].helpaction && giveData[0].helpaction === 'no') {
					return 'Declined';
				} else if (giveData.length && giveData[0].helpaction && giveData[0].helpaction === 'yes') {
					return 'Responded';
				} else if (feedbackData && feedbackData.length && feedbackData[0].flag === 1) {
					return 'Requested Feedback';
				} else if (feedbackData && feedbackData.length && feedbackData[0].flag === 2) {
					return '1st Feedback Reminder';
				} else if (feedbackData && feedbackData.length && feedbackData[0].flag === 3) {
					return 'Feedback';
				} else {
					return 'N/A';
				}
			}
		},
        checkData: function(value) {
            if (value) { return value;}
            else {return 'N/A';}
        },
        sortResponses: function(a, b, key, isAsc) {
            if (key === 'sent') {
                if (this.$moment(a[key], 'MM-DD-YYYY').isValid() && this.$moment(b[key], 'MM-DD-YYYY').isValid()) {
                    if (this.$moment(a[key], 'MM-DD-YYYY').isBefore(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return 1
                    } else if (this.$moment(a[key], 'MM-DD-YYYY').isAfter(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return -1
                    } else if (this.$moment(a[key], 'MM-DD-YYYY').isSame(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return 0
                    }
                } else {
                    return isAsc ? -1 : null;
                }
            } else if (key === 'responded') {
                var isValid = function(date) {
                    return !isNaN(isFinite(date)) && date !== 'N/A'
                }
                var lastA = a[key];
                var lastB = b[key];
                if (isAsc === false && isValid(lastA) && isValid(lastB)) {
                    return lastA < lastB ? -1 : 1;
                }
                if (isAsc === true && isValid(lastA) && isValid(lastB)) {
                    return lastA > lastB ? 1 : -1;
                }
            }
        },
        sortAdvisers: function(a, b, key, isAsc) {
            if (key === 'last_request' || key === 'last_response') {
                if (this.$moment(a[key], 'MM-DD-YYYY').isValid() && this.$moment(b[key], 'MM-DD-YYYY').isValid()) {
                    if (this.$moment(a[key], 'MM-DD-YYYY').isBefore(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return 1
                    } else if (this.$moment(a[key], 'MM-DD-YYYY').isAfter(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return -1
                    } else if (this.$moment(a[key], 'MM-DD-YYYY').isSame(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return 0
                    }
                } else {
                    return null;
                }
            }
        },
        sortFeedbacks: function(a, b, key, isAsc) {
            if (key === 'received') {
                if (this.$moment(a[key], 'MM-DD-YYYY').isValid() && this.$moment(b[key], 'MM-DD-YYYY').isValid()) {
                    if (this.$moment(a[key], 'MM-DD-YYYY').isBefore(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return 1
                    } else if (this.$moment(a[key], 'MM-DD-YYYY').isAfter(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return -1
                    } else if (this.$moment(a[key], 'MM-DD-YYYY').isSame(this.$moment(b[key], 'MM-DD-YYYY'))) {
                        return 0
                    }
                } else {
                    return null;
                }
            }
        },
        getStatus: function (status) {
            if (status === 'Approved') {
                status = 'Accepted';
            } else if (status === 'Rejected') {
                status = 'Declined';
            }
            return status;
        },
        goToProfile(Id){
            let route = this.$router.resolve({path: `/engagement/members/${Id}`});
            window.open(route.href, '_blank');
        },
        setStatus: function(status) {
            return setRequestStatus(status);
        },
        // scrollTo: function() {
        //     const elmnt = document.getElementById('commentView');
        //     elmnt.scrollIntoView();
        // },
        openComment: function() {
            this.addCommentFlag = true;
            setTimeout(function () {
                const elmnt = document.getElementById('commentForm');
                elmnt.scrollIntoView();
            }, 0);
        },
        openRequestActionUrl: function (askId) {
            window.open(`${process.env.VUE_APP_WEB_BASE_URL}/request-help/?askid=${askId}&memberid=null&helpaction=yes`, '_blank');
        },
        resetResponseDialog: function () {
			this.resAdvisorEmail = this.addResAdvisorErrMsg = '';
			this.advisorResponse = "";
			this.errorAdvisorResponse = "";
		},
		closeResponseDialog: function () {
			this.resetResponseDialog();
			this.showResponseModal = false;
		},
        showResponseDialog: async function (requestId) {
			console.log("requestId: ",requestId);
			this.selectedRequestId = requestId;
			this.resetResponseDialog();
			this.showResponseModal = true;
		},
		async addManualResponse(advisor) {
			if (this.ask.status !== '(8) Rejected' && this.ask.status !== '(7) Closed') {
				await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/asks/${this.ask._id}/request/respond`, {advisers: [advisor], insight: this.advisorResponse}, { headers: {'x-access-token': this.token}}).then(ask => {
					this.$emit("reload");
					this.loadData();
                    // this.showSuccessMsg({message: 'Added manual response successfully'})
					this.closeResponseDialog();
				});
			} else {
                // console.log("this.community :", this.ask.community);
                let closeMessage = this.ask.community.content.closeRequestMessage;
				console.log(closeMessage);
			}
		},
        addAdvisorResponseSubmit: async function (scope) {
			// console.log("this.resAdvisorEmail : ", this.resAdvisorEmail);
			this.$validator.validateAll(scope).then(async result => {
				// console.log("result :", result);
                if (result) {
					if(this.resAdvisorEmail == "") {
						this.addResAdvisorErrMsg = 'The email field is required'
					} else if(this.advisorResponse == "") {
						this.errorAdvisorResponse = 'Please add the advisor response'
					} else {
                        this.showSuccessMsg({message: 'Thank you for submitting a response.'});
						this.showResponseModal = false;
						const matchAdvisor = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/member/get-match-community-advisor`,
						{
							askId: this.selectedRequestId,
							advisorEmail: this.resAdvisorEmail
						},{
							headers: {'x-access-token': this.token}
						});

						// console.log("result : ", matchAdvisor);
						if(matchAdvisor.data.length > 0) {
							// add response
							this.addManualResponse(matchAdvisor.data[0]._id)
						} else {
							// add new member referral for unknown email.
							const referralMember = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/unknown-member`,
							{
								askId: this.selectedRequestId,
								advisorEmail: this.resAdvisorEmail,
								insight: this.advisorResponse
							},{
								headers: {'x-access-token': this.token}
							});

							// console.log("Referral Member : ", referralMember);

							//    // req body for referral response
							const responseObj = {
								email: this.resAdvisorEmail,
								insight: this.advisorResponse,
								action: "Referred Insight",
								help: "request-help",
								helpaction: "yes",
								phoneNo: "",
								calltext: false,
								callMessage: "",
								isTermsChecked: false,

							}

							// add response for referral .
							const referralResponse = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/respond/ask/${this.selectedRequestId}`,
							responseObj,{
								headers: {'x-access-token': this.token}
							});

							// console.log("Referral Response : ", referralResponse);
                            let authMemberDetails = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/auth-member-details/${this.authUser.memberID}`,{
								headers: {'x-access-token': this.token}
							});
							// console.log("authMemberDetails : ", authMemberDetails);
                           // req body for referral.
                           const referralObj = {
								askId: this.selectedRequestId,
								giveId: referralResponse.data.giveId,
								referral: {
									recipient: {
										name: "",
										email: this.resAdvisorEmail
									},
									adviser: {
										name: authMemberDetails.data.displayName ? authMemberDetails.data.displayName: authMemberDetails.data.first + " " + authMemberDetails.data.last,
										email: authMemberDetails.data.emails[0],
									},
									message: this.advisorResponse,
                           		},
								memberId: authMemberDetails.data._id,
								profileUpdateFlag: authMemberDetails.data.profileValidationFlag,
							}

							// add referral data.
							const referral = await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/store-referral`,
							referralObj,{
								headers: {'x-access-token': this.token}
							});

							// console.log("Referral : ", referral);

							this.$emit("reload");
							this.loadData();
							// this.showSuccessMsg({message: 'Added manual referral response successfully'})
							this.closeResponseDialog();
						}
					}
				}
			})
		},
        resetShareRequestModal: function () {
			this.recipientEmail = this.addRecipientEmailErrMsg = '';
		},
		closeShareRequestModal: function () {
			this.resetShareRequestModal();
			this.showShareRequestModal = false;
		},
        shareRequestModal: function () {
            this.recipientEmail = this.addRecipientEmailErrMsg = '';
            if (this.authUser) {
                this.showShareRequestModal = true;
            } else {
                this.showErrorMsg();
            }
        },
        referRequest: function (askId,memberId) {
			console.log("askId: ", askId);
			console.log("memberId: ", memberId);
			window.open(`${process.env.VUE_APP_WEB_BASE_URL}/request-share/?askid=${askId}&memberid=${memberId}&helpaction=no&action=share&location=dashboard`);
        },
        submitShareRequest: function(recipientEmail = '') {
            this.$validator.validateAll().then(async result => {
                if(result) {
                    this.showShareRequestModal = false; 
                    this.loadingShareRequest = true
                    let memberId = this.authUser && this.authUser.memberID;
                    let authMemberDetails = await this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/dashboard/auth-member-details/${this.authUser.memberID}`,{ 
						headers: {'x-access-token': this.token}
					});
                    if(memberId != '' && recipientEmail != '' && this.ask && this.ask._id) {
                        const giveId = await this.getGiveId(this.ask._id, memberId);
						// console.log("give Id [submitShareRequest] [request detail page]: ", giveId);
						const referralObj = {
							askId: this.ask._id,
							giveId: giveId,
							referral: {
								recipient: {
									name: "",
									email: this.recipientEmail
								},
								adviser: {
									name: authMemberDetails.data.displayName ? authMemberDetails.data.displayName: authMemberDetails.data.first + " " + authMemberDetails.data.last,
									email: authMemberDetails.data.emails[0],
								},
								message: "I'm trying to help out someone from {{ community.name }} and thought of you - are you able to help? If so, just click below to respond directly."
							},
							memberId: memberId,
							profileUpdateFlag: authMemberDetails.data.profileValidationFlag,
						}
						// console.log("this ask [submitShareRequest] [request detail page]:", this.ask)
						// taken referral message from community content
						if (this.ask && this.ask.community && this.ask.community.content.referralMessage) {
							referralObj.referral.message = this.ask.community.content.referralMessage;
						}

						// replace community name dynamic text from referral message.
						let regexPattern = new RegExp(`\\{\\{\\s?community.name\\s?\\}\\}`, 'gi');
						if (this.ask && this.ask.community && this.ask.community.name) {
							referralObj.referral.message = referralObj.referral.message.replace(regexPattern, this.ask.community.name);
						} else {
							referralObj.referral.message = referralObj.referral.message.replace(regexPattern, '');
						}

						await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/store-referral`, 
							referralObj,{ 
								headers: {'x-access-token': this.token}
							});
                        this.showSuccessMsg({message: 'Thank you. We have successfully shared your request.'})
                    } else {
                        this.showErrorMsg();
                    }
                    this.loadingShareRequest = false;
                    this.showShareRequestModal = false;
                }
            })
        },
        getGiveId: async function(askId, memberId) {
			let giveId = "";
            let parameters = {
                advisors: [memberId],
                action: 'Not Available',
                help: 'request-help',
                helpaction: 'no'
            };
            await this.axios
            .post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/referrals/respond/ask/${askId}`, parameters, {headers: {'x-access-token': this.token}})
            .then(response => {
                if (response.data.giveId) {
                    giveId = response.data.giveId;
                }
            });
			return giveId;
        },
        rejectRequest: async function (askId) {
            await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/reject`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(response => {
                this.loadData();
            });
        },
        reOpenRequest: async function (askId) {
            await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/reopen`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(response => {
                this.loadData();
            });
        },
        closeRequest: async function (askId) {
            await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/close`, {askId: askId},{ headers: {'x-access-token': this.token}}).then(response => {
                this.loadData();
            });
        },
        async favoriteRequest(requestId, requestType) {
			const favorite = requestType == 'favorite' ? true: false;
			await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/favorite-request`, {askId: requestId, favorite: favorite, requestType: requestType},{ headers: {'x-access-token': this.token}}).then(async (response) => {
                this.loadData();
			});
		},
        addComment: async function() {
            if (this.comment.description && this.comment.description.length) {
                this.comment.askId = this.ask._id;
                await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/comment`, this.comment, { headers: {'x-access-token': this.token}}).then(async response => {
                    this.comment = {};
                    if (response.data.comments) {
                        this.getComment(response.data);
                    }
                });
            }
        },
        getComment: function(data) {
            data.comments = this.$lodash.orderBy(data.comments, ['when'], ['desc'])
            if (this.authUser.role == 'Admin') {
                data.comments = this.$lodash.filter(data.comments, function(o) { return o.commentBy != 'SuperAdmin'; })
            }
            this.comments = data.comments;
            this.commentCount = data.comments.length;
        },
        tagRequest: function(tag) {
            window.open((`/engagement/requests/?tag=${tag}`), '_blank');
        },
        UpdateAskDetail: async function(type) {
            if (type == 'subject' && this.ask.subject) {
                if (this.oldSubject.toString() != this.ask.subject.toString()) {
                    await this.axios.put(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/edit-request-detail`, {subject: this.ask.subject, id: this.ask._id}, { headers: {'x-access-token': this.token}}).then(async response => {
                        if (response && response.data && response.data.subject) {
                            this.ask.subject = response.data.subject;
                            this.ask.oldSubject = response.data.subject;
                        }
                    });
                }
            } else if (type == 'text') {
                if (this.oldText.toString() != this.ask.text.toString()) {
                    await this.axios.put(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request/edit-request-detail`, {text: this.ask.text, id: this.ask._id}, { headers: {'x-access-token': this.token}}).then(async response => {
                        if (response && response.data && response.data.text) {
                            this.ask.text = response.data.text;
                            this.ask.oldText = response.data.text;
                        }
                    });
                }
            } else {
                this.ask.subject = this.oldSubject;
                this.ask.text = this.oldText;
            }
        },
        validateState(ref) {
            if (this.errors.has(ref)) {
                return !this.errors.has(ref);
            } else {
                return null;
            }
        },
        redirectToCoachPage(requestId) {
			this.$router.push({
                path: `/engagement/requests-coach/${requestId}`,
                query: {
                    from:'request-detail-page'
                }
            });
		},
        opnAddAdvisorModal() {
            this.advisorEmail = this.addAdvisorErrMsg = '';
            this.addAdvisorModal = true;
        },
        addAdvisorEmailSubmit() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    let memberObj = {
                        email: this.advisorEmail,
                        askId: this.ask._id
                    }
                    await this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/ask/add-advisor`, memberObj, {headers: {'x-access-token': this.token}}).then(async response => {
                        if (response.data.success == false && response.data.message) {
                            this.addAdvisorErrMsg = response.data.message
                        } else {
                            this.loadData();
                            this.showSuccessMsg();
                            this.addAdvisorModal = false;
                            this.advisorEmail = this.addAdvisorErrMsg = '';
                        }
                    }).catch(err => {
                        if (err.response.status  === 401) {
                            localStorage.removeItem('authUser');
                            this.$router.push('/login');
                        }
                    });
                }
            })
        },
        approveRequest: function(askId, action, status) {
			let params = {
				askId: askId,
				action: action,
				status: status,
				approvedBy: 'dashboard'
			}
			if (this.authUser.memberID) {
				params.adminId = this.authUser.memberID;
			}
			this.axios.post(`${process.env.VUE_APP_API_BASE_URL}/dashboard/request-approval`, params, {headers: {'x-access-token': this.token}}).then(async response => {
				if (action == 'approve') {
					this.showSuccessMsg({message: 'Thank you for approving the request.'})
				} else if (action == 'reject') {
					this.showSuccessMsg({message: 'Thank you for your response'})
				}
				await this.loadData();

			}).catch(err => {
                console.log("err :", err);
				if (err.response.status  === 401) {
					localStorage.removeItem('authUser');
					this.$router.push('/login');
				}
			});
		},
        
        // openStory: function(requestId) {
        //     window.open((`/engagement/requests/${requestId}/story`), '_blank');
        // }
    }
}
</script>

<style>
td:first-child {
    text-transform: none;
}
</style>